import { combineReducers } from "redux";
import recursosReducer from "./recursosReducer";
import authReducer from "./authReducer";
import backofficeReducer from "./backofficeReducer";
import previewReducer from "./previewReducer";
import previewInstitucionalesReducer from "./previewInstitucionalesReducer";
import previewNoticiasReducer from "./previewNoticiasReducer";

const rootReducer = combineReducers({
    backoffice: backofficeReducer,
    recursos: recursosReducer,
    authReducer: authReducer,
    previewPortadaReducer: previewReducer,
    previewInstitucionalesReducer: previewInstitucionalesReducer,
    previewNoticiasReducer: previewNoticiasReducer
});

export default rootReducer;
