import React, { useEffect } from 'react';
// import { connect } from 'react-redux';

// import { getAllRecursos } from 'src/redux/actions/recursosAction';
import Loading from 'src/components/shared/loading';

const DatosAbiertosContainer = props => {
    useEffect(() => {
        // props.getAllRecursos()
        window.location.href = `${process.env.REACT_APP_REA}/datosabiertos`;
        // eslint-disable-next-line
    }, [])

    return <Loading />    
}
/* 
const mapStateToProps = ({recursos}) => {
    return {
        recursos,
    };
};

const mapDispatchToProps = {
    getAllRecursos
}

export default connect(mapStateToProps, mapDispatchToProps)(DatosAbiertosContainer);
 */
export default DatosAbiertosContainer;
