import React from 'react';
import { stripHTML } from 'src/helpers';

const BreadCrumb = props => {
    const { history, actualPosition, previousPositions, match } = props;

    const linkHref = (position) => position === "Inicio" ? `/inicio`: position.replace(" ", "").toLowerCase();

    return (
        <nav aria-label="breadcrumb" id="breadCrumb">
            <ol className="breadcrumb px-0">
                {
                    previousPositions.map((position, index) => 
                        <li key={index} className="breadcrumb-item">
                            <a href={linkHref(position)} onClick={(event) => {
                                event.preventDefault();
                                if(position === "Inicio"){
                                    history.push("/inicio")//esto es una negrada pero sino tenia que cambiar todo en el deploy de educar y en todos los history
                                    //comparto el sentimiento
                                }else{
                                    if(match.path === '/'+position.toLowerCase() + '/:recursoId') {
                                        let prevUrl = document.referrer
                                        if(prevUrl.indexOf("/recursos") !== -1){
                                            history.goBack();
                                        }else{
                                            history.push("/recursos")
                                        }
                                    
                                    } else {
                                        history.push('/'+ position.toLowerCase());
                                    }
                                }
                                
                            }}>{position}</a>
                        </li>
                    )
                }

                <li className="breadcrumb-item active" aria-current="page">
                    { stripHTML(actualPosition) }
                </li>
            </ol>
        </nav>
    )
}
export default BreadCrumb;