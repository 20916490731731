import React from 'react';
import { Link } from 'react-router-dom';
import IconRecurso from '../iconRecurso';

const CardHeader = ({ format, id, src }) =>
    <Link
        className="carousel-header"
        // id={`UncontrolledTooltip-${id}`}
        to={`/recurso/${id}`}
    >
        <div className="img-event">
            <img
                className="group list-group-image img-fluid card-img-top"
                src={src}
                alt={`Recurso-${id}`}
                style={{ height: "inherit", marginBottom: "20px" }}
            />
            <IconRecurso
                format={format.id}
                claseIcon="icono-carousel-buscador"
                styles={{ borderRadius: "25px !important" }}
            />
        </div>
    </Link>

export default CardHeader;
