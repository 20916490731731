import React from 'react'
import { VIDEO, GALERIA_IMAGENES, LIBRO_ELECTRONICO, AUDIO, TEXTO, INTERACTIVO, COLECCION } from 'src/util/constantes'

const IconRecurso = ({format, claseIcon, styles}) => {
    return (
        <>
            {
                format === VIDEO &&
                <div className={`${claseIcon} videos shadow`} style={{...styles}}></div>
            }
            {
                format === GALERIA_IMAGENES &&
                <div className={`${claseIcon} galerias shadow`} style={{...styles}}></div>
            }
            {
                format === LIBRO_ELECTRONICO &&
                <div className={`${claseIcon} libros shadow`} style={{...styles}}></div>
            }
            {
                format === AUDIO &&
                <div className={`${claseIcon} audio shadow`} style={{...styles}}></div>
            }
            {
                format === TEXTO &&
                <div className={`${claseIcon} textos shadow`} style={{...styles}}></div>
            }
            {
                format === INTERACTIVO &&
                <div className={`${claseIcon} juegos shadow`} style={{...styles}}></div>
            }
            {
                format === COLECCION &&
                <div className={`${claseIcon} biblioteca shadow`} style={{...styles}}></div>
            }
        </>
    )
}

export default IconRecurso

