import React, { useEffect, useState } from 'react';
import { NotificationManager } from 'react-notifications';
import ReactHtmlParser from 'html-react-parser';

import { CircularProgress } from '@material-ui/core';

import { BackofficeService } from 'src/axios/index';

import BreadCrumb from '../shared/breadcrumb';

const InputField = ({ children, fieldName, errors, required, ...props }) => {
    const isInvalid = errors && errors[fieldName];

    function handleClick(event) {
        event.target.classList.remove('is-invalid');
    }

    return (
        <React.Fragment>
            <label className="attachedLabel" htmlFor={fieldName}>
                {children}{required && "*"}
            </label>
            <input
                className={"form-control form-control-lg" + ((isInvalid) ? " is-invalid" : "")}
                id={fieldName} name={fieldName}
                required={required}
                onClick={handleClick}
                {...props}
            />
        </React.Fragment>
    );
}

const FieldError = ({ errors, fieldName }) => {
  return (
    <>
        {errors && errors[fieldName] && (
            <div class="invalid-tooltip" role="alert">
                {errors[fieldName].map((error) => (
                    <small>{error}</small>
                ))}
            </div>
        )}
    </>
  )
}

const Contacto = props => {
    const { history, match, contact, user } = props;
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    useEffect(() => {
        if(loading) {
            setErrors({});
        }
    }, [loading])

    function handleSubmit(event) {
        event.preventDefault();
        const fields = event.target;
        const data = {
            name: fields["name"].value,
            email: fields["email"].value,
            cuil: fields["cuil"].value,
            subject: fields["subject"].value,
            body: fields["cuerpo"].value
        }
        
        // const body=`Nombre y Apellido: ${data.name}\nMensaje: \n${data.cuerpo}`
        // window.location.href=`mailto:edgardo.lacquaniti@gmail.com?subject=${encodeURI(data.asunto)}&body=${encodeURI(body)}`
        setLoading(true);
        BackofficeService.sendMail(data)
            .then(function(response) {
                // NotificationManager.success("Mail enviado correctamente");

                if(!user) {
                    fields["name"].value="";
                    fields["email"].value="";
                    fields["cuil"].value="";
                }
                fields["subject"].value="";
                fields["cuerpo"].value="";

                history.push('contacto/exito');
            })
            .catch(function(error) {
                NotificationManager.error("Error al enviar el mail");
                setErrors(error?.response?.data?.errors);
            })
            .finally(function() {
                setLoading(false);
            });
    }

/* 
    function handleKeyPress(event) {
        const pattern = new RegExp('^[0-9]$');

        if (!pattern.test(event.key)) {
            event.preventDefault();
        }
    }

    function validatePattern(event) {
        const target = event.target;
        const pattern = new RegExp('^' + target.pattern + '$');
        // check each line of text
        const hasError = !target.value.match(pattern);
        if (typeof target.setCustomValidity === 'function') {
            target.setCustomValidity(hasError ? target.title : '');
        } else {
            // Not supported by the browser, fallback to manual error display...
            target.classList.toggle("error", !!hasError);
            target.classList.toggle("ok", !hasError);
        }
        return !hasError;
    }
 */
    return (
        <div className="main_content">
            <div className="container-fluid">
                <div className="col-xl-8 offset-xl-2 col-12">
                    <BreadCrumb match={match} history={history} actualPosition={"Contacto"} previousPositions={['Inicio']} />
                </div>
            </div>
            <div className="col-xl-8 offset-xl-2 col-12 mb-5 mt-5">
                <div className="container mx-0">
                    <div className="col-md-12 px-0">
                        <h1 className="tc-1 mb-0">Contacto</h1>
                        {/* <p className="justify">¿Tenés dudas, consultas acerca de problemas en el registro y creación de aulas o en el uso de los recursos? Te podemos ayudar.</p> */}
                        <div className="row">           
                            {errors && (errors["errors"] || errors["error"]) && (
                                <div className="col-12 cartel-contacto">
                                    {errors["errors"].map((errorArray) => (
                                        <ul class="mb-0 pl-2 invalid-general-error">
                                            {errorArray.map((error) =>
                                                <li>{error}</li>
                                            )}
                                        </ul>
                                    ))}
                                    {errors["error"] && (
                                        <ul class="p-0 invalid-general-error">
                                            <li>{errors["error"]}</li>
                                        </ul>
                                    )}
                                </div>
                            )}
                            <div className="col-md-7 mt-0">
                                <form onSubmit={handleSubmit}>
                                    <div className="form-group my-1">
                                        <InputField
                                            defaultValue={user ? `${user.name} ${user.lastname}` : ""}
                                            fieldName="name" maxLength="255"
                                            placeholder="Ingresar nombre y apellido"
                                            type="text"
                                            errors={errors}
                                        >
                                            Nombre
                                        </InputField>
                                        <FieldError errors={errors} fieldName="name"/>
                                    </div>
                                    <div className="form-group my-1">
                                        <InputField
                                            defaultValue={user ? (user.email && user.email !== "NULL" ? user.email : '') : ""}
                                            fieldName="email" maxLength="255"
                                            placeholder="Ingresar correo electrónico"
                                            type="email"
                                            errors={errors}
                                        >
                                            Correo electrónico
                                        </InputField>
                                        <FieldError errors={errors} fieldName="email"/>
                                    </div>
                                    <div className="form-group my-1">
                                        <InputField
                                            defaultValue={user ? user.cuil : ""}
                                            fieldName="cuil" maxLength="11"
                                            placeholder="Ingresar número de CUIL sin guiones"
                                            type="text"
                                            errors={errors}
                                        >
                                            CUIL
                                        </InputField>
                                        <FieldError errors={errors} fieldName="cuil"/>
                                    </div>
                                    <div className="form-group my-1">
                                        <InputField
                                            fieldName="subject" maxLength="255"
                                            placeholder="Ingresar asunto"
                                            type="text"
                                            errors={errors}
                                        >
                                            Asunto
                                        </InputField>
                                        <FieldError errors={errors} fieldName="subject"/>
                                    </div>
                                    <div className="form-group my-1">
                                        <label className="attachedLabel" htmlFor="cuerpo">Mensaje*</label>
                                        <textarea
                                            className={"form-control" + ((errors && errors["body"]) ? " is-invalid" : "")}
                                            id="cuerpo" name="cuerpo" maxLength="2047" rows="4"
                                            placeholder="Ingresar mensaje"
                                            onClick={(event) => event.target.classList.remove('is-invalid')}
                                        />
                                        <FieldError errors={errors} fieldName="body"/>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-md-12 text-right">
                                            <button
                                                className="btn btn-success btn-conectar-igualdad float-right shadow-lg ripple"
                                                disabled={loading}
                                            >
                                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                    {loading && <CircularProgress size="1.5rem" className="mr-2" />}
                                                    <span style={{ fontFamily: 'Encode Sans' }}>ENVIAR</span>
                                                </div>
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="col-md-5" style={{ marginTop: "30px" }}>
                                <div className="card jm4 shadow-none">
                                    <div className="card-body text-white ck-content">
                                    {ReactHtmlParser(contact ?? '')}
                                        {/* <h3 className="lh-20">Datos</h3>
                                        <p className="font-weight-light max-ch lh-20">0800 444 1115</p>
                                        <a href="mailto:info@juanamanso.edu.ar"
                                            className="font-weight-light max-ch lh-20 text-white">info@juanamanso.edu.ar</a>
                                        <hr style={{ borderColor: "#fff" }} />
                                        <p>Horarios de atención:<br />
                                            Lunes - Viernes<br />
                                                08:00 - 20:00 hs</p> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Contacto;