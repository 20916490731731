import React from "react";
import { Col } from "react-bootstrap";

import LinkComponent from "src/components/shared/LinkComponent";

const FootBar = ({ options }) => (
  <div className="d-flex flex-column flex-lg-row text-center">
    {options &&
      options.map((item) => (
        <div key={item.id}>
          <Col className="mb-2 mb-lg-0">
            {item.link_type !== "children" && (
              <LinkComponent href={item.link} className="text-white">
                {item.name}
              </LinkComponent>
            )}
          </Col>
        </div>
      ))}
  </div>
);

export default FootBar;
