import React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

import BreadCrumbComponent from 'src/components/shared/breadcrumb';

const BreadCrumb = ({
    actualPosition,
    previousPositions
}) => {
    const match = useRouteMatch();
    const history = useHistory();

    return (
        <div className="container-fluid">
            <div className="col-xl-10 offset-xl-1 col-12">
                <BreadCrumbComponent match={match} history={history} actualPosition={actualPosition} previousPositions={previousPositions} />
            </div>
        </div>
    )
}

export default BreadCrumb
