import { UserTypes } from "../types";

const INIT_STATE = {
    user: null,
    userMenu: null,
    user_logued: false,
    error: null,
    loading: false
}

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case UserTypes.LOADING:
            return {
                ...state,
                loading: true
            }
        case UserTypes.NOT_LOADING:
            return {
                ...state,
                loading: false
            }
        case UserTypes.FETCH_SUCCESS:
            return {
                ...state,
                user: action.payload,
                error: null,
                user_logued: true
            }
        case UserTypes.FETCH_FAILURE:
            return {
                ...state,
                user: null,
                error: action.payload,
                user_logued: false
            }
        case UserTypes.SET_MENU:
            return {
                ...state,
                userMenu: action.payload
            }
        default:
            return { ...state }
    }
}
