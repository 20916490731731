import React, { useState, useEffect } from "react";
import { Container, Row, Col, Pagination } from "react-bootstrap";
import NoticiaparaConjunto from "./NoticiaParaConjunto";
import { BackofficeService } from "src/axios";

export function ComponenteConjuntoNoticias({ componente }) {
  const bgColor = componente.json_data?.bg_color || "white";

  const [noticias, setNoticias] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    const obtenerNoticias = async () => {
      try {
        const response = await BackofficeService.getNoticias(currentPage);
        const data = response.data;

        setNoticias(data.data); 
        setTotalPages(data.last_page); 
      } catch (error) {
        console.error("Error al obtener noticias:", error);
      }
    };

    obtenerNoticias();
  }, [currentPage]);

  return (
    <>
      <Container style={{ backgroundColor: bgColor }}>
        {noticias
          .reduce((acc, noticia, index) => {
            if (index % 2 === 0) {
              acc.push([]);
            }
            acc[acc.length - 1].push(noticia);
            return acc;
          }, [])
          .map((grupo, index) => (
            <Row key={index}>
              {grupo.map((noticia) => (
                <Col
                  key={noticia.id}
                  xs={12}
                  md={6}
                  className={`mb-4 ${window.innerWidth < 768 ? "p-0" : ""}`}
                >
                  <NoticiaparaConjunto componente={noticia} />
                </Col>
              ))}
            </Row>
          ))}
      </Container>

      <Pagination className="justify-content-center paginador">
        <Pagination.Prev
          onClick={() =>
            setCurrentPage((prevPage) => Math.max(prevPage - 1, 1))
          }
          disabled={currentPage === 1}
        />

        {[...Array(totalPages)].map((_, index) => (
          <Pagination.Item
            key={index + 1}
            active={index + 1 === currentPage}
            onClick={() => setCurrentPage(index + 1)}
          >
            {index + 1}
          </Pagination.Item>
        ))}

        <Pagination.Next
          onClick={() =>
            setCurrentPage((nextPage) => Math.min(nextPage + 1, totalPages))
          }
          disabled={currentPage === totalPages}
        />
      </Pagination>
    </>
  );
}
