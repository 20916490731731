import axios from "axios";
import backoffice from "../api/backoffice";
import jmi from "../api/jmi";

const fetchJson = (route) =>
  backoffice.get(`${route}.json?${Date.now()}`).then((res) => res.data);

const BackofficeService = {
  sendMail: (data) => jmi.post("/contact", data),
  getSitemap: () =>
    axios.get(`${process.env.REACT_APP_BASE_URL_API}/sitemap.xml`),
  fetchJson: (route) => fetchJson(route),
  fetchContactText: () => fetchJson("/contact/contact"),
  fetchHeaderMenu: () => fetchJson("/header/menu"),
  fetchHeaderLogo: () => fetchJson("/header/logo"),
  fetchHeaderText: () => fetchJson("/header/headerTexto"),
  fetchFooterMenu: () => fetchJson("/footer/menu"),
  fetchFooterLogo: () => fetchJson("/footer/logo"),
  fetchFooterLogoCentro: () => fetchJson("/footer/logo_centro"),
  fetchFooterText: () => fetchJson("/footer/texto"),
  fetchFooterText2: () => fetchJson("/footer/texto2"),
  fetchPortadas: () => fetchJson("/portadas/index"),
  getPortadaDataBackoffice: (portadaId) =>
    backoffice.get(`/portadas/${portadaId}.json?${Date.now()}`),
  getFAQS: () => backoffice.get(`/faqs/faq.json?${Date.now()}`),
  fetchInstitucionales: () => fetchJson("/paginas_institucionales/index"),
  getPaginaInstitucionalDataBackoffice: (paginaId) =>
    backoffice.get(`/paginas_institucionales/${paginaId}.json`),
  getTempPaginaInstitucionalDataBackoffice: (paginaId) =>
    backoffice.get(`/paginas_institucionales/tmp/${paginaId}.json`),
  fetchNoticias: () => fetchJson("/noticias/index").catch((error) => []),
  getNoticiasDataBackofficee: (noticiaId) =>
    backoffice.get(`/noticias/${noticiaId}.json`),
  getTempNoticiasDataBackoffice: (noticiaId) =>
    backoffice.get(`/noticias/tmp/${noticiaId}.json`),
  getTagScriptsBackoffice: () => backoffice.get(`/visitor_register/index.json`),
  fetchTagScripts: () => backoffice.get(`/visitor_register/index.json`),
  fetchUserMenu: () => fetchJson("/user/menu"),
  fetchUserPanel: () => fetchJson("/user/panel"),
  fetchLastNews: () => fetchJson("/noticias/last_news"),
  getNoticias: (pagina) =>
    backoffice.get("/noticias_frontend/noticias_page_" + pagina + ".json"),
  getDetalle: (slug) =>
    backoffice.get("/noticias_frontend/detalles/" + slug + ".json"),
};

export { BackofficeService as default };
