
import axios from 'axios'
// URL DE LA API
const axiosInstance = axios.create({
   baseURL: process.env.REACT_APP_BASE_URL_OAUTH,
   timeout: 60000
});

axiosInstance.interceptors.request.use(
   request => requestHandler(request)
);

axiosInstance.interceptors.response.use(
   response => successHandler(response),
   error => errorHandler(error)
 )

const requestHandler = (request) => {
   // let jwt="";
   try {
      // Desencriptar antes de enviar request
      // const bytes  = CryptoJS.AES.decrypt(localStorage.getItem('access_token'), process.env.KEY_TOKEN_ENCRYPTED);
      // jwt= bytes.toString(CryptoJS.enc.Utf8);
      // jwt  = getCookie('access_token');
    //   jwt= bytes.toString(CryptoJS.enc.Utf8);
   } catch (error) {
      console.log("Error de token de login. No es un error real")
   }
   
   // Modify request here
   request.headers['Content-Type'] = 'application/json';
   request.headers['Accept'] = 'application/json';

   return request;
}

const errorHandler = (error) => {
    return Promise.reject({...error})
 }

 const successHandler = (response) => {
   return response
 }

export default axiosInstance;