import FAQContainer from "src/containers/FAQContainer";
import DatosAbiertosContainer from "src/containers/DatosAbiertosContainer";
import HomeUsuarioContainer from "src/containers/usuarioDatos/HomeUsuarioContainer";
import ReaContainer from "src/containers/ReaContainer";
import MisDatosContainer from "src/containers/usuarioDatos/MisDatosContainer";
import ContactoContainer from "src/containers/ContactoContainer";
import Capacitacion from "src/components/institucionales/capacitacion";
import PreviewPortadasContainer from "src/containers/PreviewPortadasContainer";
import PreviewInstitucionalesContainer from "src/containers/PreviewInstitucionalesContainer";
import PreviewNoticiasContainer from "src/containers/PreviewNoticiasContainer";
import PageBlank from "src/components/backoffice/PageBlank";

export { default as redirects } from './redirects';

export default [
   {
      path: 'faq',
      component: FAQContainer
   },
   {
      path: 'datosabiertos/:recursoId',
      component: DatosAbiertosContainer
   },
   {
      path: 'datosabiertos',
      component: DatosAbiertosContainer
   },
   {
      path: 'home-usuario',
      component: HomeUsuarioContainer
   },
   {
      path: 'mis-datos',
      component: MisDatosContainer,
   },
   {
      path: 'rea',
      component: ReaContainer
   },
   {
      path: 'contacto',
      component: ContactoContainer
   },
   {
      path: 'contacto/exito',
      component: ContactoContainer
   },
   {
      path:"capacitacion",
      component:Capacitacion
   },
   {
      path:"preview/portada/:portadaId",
      component: PreviewPortadasContainer
   },
   {
      path:"preview/header",
      component: PageBlank
   },
   {
      path:"preview/footer",
      component: PageBlank
   },
   {
      path:"preview/pagina-institucional/:paginaId",
      component: PreviewInstitucionalesContainer
   },
   {
      path:"preview/noticia/:paginaId",
      component: PreviewNoticiasContainer
   }
]

