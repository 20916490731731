import React from 'react';
// import { useHistory } from 'react-router-dom';

import { AUDIO, VIDEO, GALERIA_IMAGENES, TEXTO, INTERACTIVO, LIBRO_ELECTRONICO } from 'src/util/constantes';

const iconos = {
    [AUDIO]: {
        className: "audio"
    },
    [VIDEO]: {
        className: "videos"
    },
    [GALERIA_IMAGENES]: {
        className: "galerias"
    },
    [TEXTO]: {
        className: "textos"
    },
    [INTERACTIVO]: {
        className: "juegos"
    },
    [LIBRO_ELECTRONICO]: {
        className: "libros"
    }
}

const IconoRecurso = ({ format, className, style = {}, ...props }) => {
    // const history = useHistory();
    const icon = iconos[format];

    return (
        <div
            className={`text-center ${className}`}
            style={{ cursor: "pointer", zIndex: 2 }}
            onClick={() => window.location = `${process.env.REACT_APP_REA}/recursos?resources_formats=${format}`}
        >
            <div
                className={`icono-buscador ${icon.className} shadow`}
                style={{ zIndex: 1, ...style }}
            />
            {props.children && (
                <h6 className="text-center text-white mt-2 small">{props.children}</h6>
            )}
        </div>
    );
};

export default IconoRecurso;
