
// import { ContactPhoneSharp } from '@material-ui/icons';
import axios from 'axios';

// URL DE LA API
const axiosInstance = axios.create({
   baseURL: `${process.env.REACT_APP_BASE_URL_API}/api/rea`,
   timeout: 60000,
   withCredentials: true,
});

axiosInstance.interceptors.request.use(
   request => requestHandler(request)
);

axiosInstance.interceptors.response.use(
   response => successHandler(response),
   error => errorHandler(error)
) 

const requestHandler = (request) => {
   // Modify request here
   request.headers['Content-Type'] = 'application/json';
   request.headers['Accept'] = 'application/json';

   return request;
}

const errorHandler = (error) => {
   if (error.response.status === 401) {
      return null;
   }
   return Promise.reject({...error})
 }

 const successHandler = (response) => {
   return response
 }

export default axiosInstance;