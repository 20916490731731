import React from 'react';

import LinkComponent from 'src/components/shared/LinkComponent';
import { HeaderDropdown } from './HeaderDropdown';
import UserMenu from "src/components/backoffice/usuario/UserMenu";

const NavBar = ({ options, closeMenu }) => (
    <ul className="navbar-nav ml-auto">
        {
            options && options.map(item => (
                <li key={item.id} className="nav-item">
                    {item.link_type === 'children' && (
                        <HeaderDropdown
                            name={item.name}
                            onItemClick={closeMenu}
                        >
                            {item.link}
                        </HeaderDropdown>
                    )}
                    {item.link_type !== 'children' && (
                        <LinkComponent
                            href={item.link}
                            className="nav-link cool-link"
                            onClick={closeMenu}
                        >
                            {item.name}
                        </LinkComponent>
                    )}
                </li>
            ))
        }
        {/* <li className="wrapper-userMenu-desktop">
            <UserMenu />
        </li> */}
    </ul>
);

export default NavBar;
