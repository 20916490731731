import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { getTempNoticiasDataBackoffice, getNoticiasDataBackoffice } from 'src/redux/actions/backofficeActions';

import Loading from 'src/components/shared/loading';
import Noticia from 'src/components/backoffice/Noticia';

const PreviewNoticiasContainer = props => {
    const { match, previewNoticiasReducer, history } = props;

    useEffect(() => {
        const paginaID = match.params.paginaId;
        if (paginaID < 999999)
            props.getNoticiasDataBackoffice(match.params.paginaId);
        else
            props.getTempNoticiasDataBackoffice(match.params.paginaId);
        // eslint-disable-next-line
    }, [])

    if (!previewNoticiasReducer.noticia) return <Loading />
    return (
        <Noticia
            noticia={previewNoticiasReducer.noticia}
            history={history}
        />
    );
}

const mapStateToProps = ({ authReducer, previewNoticiasReducer }) => {
    return {
        authReducer,
        previewNoticiasReducer
    };
};

const mapDispatchToProps = {
    getNoticiasDataBackoffice,
    getTempNoticiasDataBackoffice
}

export default connect(mapStateToProps, mapDispatchToProps)(PreviewNoticiasContainer);