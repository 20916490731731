import {
    GET_NOTICIA_BACKOFFICE,
    GET_NOTICIA_BACKOFFICE_SUCCESS,
    GET_NOTICIA_BACKOFFICE_FAILURE
} from '../types';

const INIT_STATE = {
    noticia: null,
    loading: false
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_NOTICIA_BACKOFFICE:
            return {
                ...state,
                loading: true
            }
        case GET_NOTICIA_BACKOFFICE_SUCCESS:
            return {
                ...state,
                noticia: action.payload,
                loading: false
            }
        case GET_NOTICIA_BACKOFFICE_FAILURE:
            return {
                ...state,
                loading: false
            }
        default: return { ...state };
    }
}