import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { getFAQSBackoffice } from 'src/redux/actions/backofficeActions';

import FAQ from 'src/components/institucionales/faq';
import Loading from 'src/components/shared/loading';

const FAQContainer = props => {
    const { history, match, backoffice} = props;

    useEffect(()=>{
        if(backoffice.faqs === null){
            props.getFAQSBackoffice()
        }
        // eslint-disable-next-line
    }, [])

    if (backoffice.faqs === null) {
		return <Loading />
	}

    return (
        <FAQ
            history={history}
            match={match}
            faqs={backoffice.faqs}
        />
    );
}

const mapStateToProps = ({backoffice}) => {
    return {
        backoffice
    };
};

const mapDispatchToProps = {
    getFAQSBackoffice
}

export default connect(mapStateToProps, mapDispatchToProps)(FAQContainer);
