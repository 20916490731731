import React, { useEffect } from 'react';
import Loading from 'src/components/shared/loading';

const MisDatosContainer = (props) => {
    const { location } = props;

    useEffect(() => {
        // const state = location.state ?? { origin: window.location.origin, from: '/recursos' }
        // const { origin, from } = state;
        window.location = `${process.env.REACT_APP_BASE_URL_OAUTH}/mi-perfil/editar`;
    }, [location]);

    return (
        <Loading />
    );
}

export default MisDatosContainer;