import React from "react";
import ReactHtmlParser from "react-html-parser";

const DestacadoIzquierda = ({ componente }) => {
  const iconoStyles = Object.assign(
    {
      backgroundImage: `url(${process.env.REACT_APP_BASE_URL_API}/${componente.image_file.filename})`,
    },
    JSON.parse(componente.image_file.styles)
  );

  const background = {
    color: {
      backgroundColor: componente.page_background.color_hex,
    },
    image: {
      backgroundImage:
        componente.page_background.image_file &&
        `url(${process.env.REACT_APP_BASE_URL_API}/${componente.page_background.image_file.filename})`,
    },
  };

  const hoverColor = componente.json_data?.hover_color || "";
    const hoverTexto = componente.json_data?.hover_text || "";

  const handleMouseOver = () => {
      const element = document.getElementById(componente.id);
      element.style.backgroundColor = hoverColor;

      // Cambiar el color del texto para los <p> y <span> dentro del div
      const textElements = element.querySelectorAll('p, span');
      textElements.forEach(el => {
          // Capturar el color original, ya sea en línea o computado
          if (!el.dataset.originalColor) {
              el.dataset.originalColor = el.style.color || window.getComputedStyle(el).color;
          }
          el.style.setProperty('color', hoverTexto, ); 
      });
  };

  const handleMouseLeave = () => {
      const element = document.getElementById(componente.id);
      element.style.backgroundColor = background.color.backgroundColor;

      // Restaurar el color original de los <p> y <span>
      const textElements = element.querySelectorAll('p, span');
      textElements.forEach(el => {
          if (el.dataset.originalColor) {
              el.style.setProperty('color', el.dataset.originalColor,); 
          }
      });
  };

  return (
    <a
      href={componente.url ? componente.url : null}
      className="card-link"
      target="_self"
      rel="noopener noreferrer"
    >
      <div
        className="card bg-card shadow-none"
        style={background[componente.page_background.type]}
        onMouseOver={handleMouseOver}
        onMouseLeave={handleMouseLeave}
        id={componente.id}
      >
        <div className="row  m-0">
          <div className="col-md-5 col-sm-12 d-flex m-0 p-0  ">
            <div className=" " style={{ ...iconoStyles, objectFit: "cover" }} />
          </div>
          <div className="col-md-7 m-0 p-0">
            <div className="row m-4">
              <div className="col-12 ">
                {ReactHtmlParser(
                  componente.title
                    ? componente.title.replace(/!important/g, "")
                    : ""
                )}
              </div>
            </div>
            <div className="row m-4">
              <div className="col-12 texto-destacadoIzquierdo">
                {ReactHtmlParser(
                  componente.copete
                    ? componente.copete.replace(/!important/g, "")
                    : ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </a>
  );
};

export default DestacadoIzquierda;
