import React from 'react';
import ReactHtmlParser from 'react-html-parser';

const Titulo = (props) => {
    const { componente } = props;
    return (
        <>{ReactHtmlParser((componente.title ?? '').replace(/!important/g, ""))}</>
    );
};

export default Titulo;
