export default {
    user: {
        name: 'John',
        lastName: 'Doe',
        gender: 'M',
        cuil: '1234567890',
        email: 'John_Doe@email.com'
    },
    userMenu: [
        {
            "id": 100,
            "name": "Panel",
            "type": "slug",
            "url": "home-usuario",
            "icon": "panel"
        },
    ],
    token: 'asd'
}