export const GET_RECURSO_BY_ID="GET_RECURSO_BY_ID"
export const GET_RECURSO_BY_ID_SUCCESS="GET_RECURSO_BY_ID_SUCCESS"
export const GET_RECURSO_BY_ID_FAILURE="GET_RECURSO_BY_ID_FAILURE"

export const UserTypes = {
    LOADING:        "USER_LOADING",
    NOT_LOADING:    "USER_NOT_LOADING",
    FETCH_SUCCESS:  "GET_USER_SUCCESS",
    FETCH_FAILURE:  "GET_USER_FAILURE",
    SET_MENU:       "SET_USER_MENU"
};

export const RecursosTypes = {
    NAME:           "recursos",
    LOADING:        "RECURSOS_LOADING",
    NOT_LOADING:    "RECURSOS_NOT_LOADING",
    FETCH:          "recursos/fetch_all",
    FETCH_SUCCESS:  "GET_RECURSOS_SUCCESS",
    FETCH_FAILURE:  "GET_RECURSOS_FAILURE"
};

export const BackofficeTypes = {
    LOADING:                "BACKOFFICE_LOADING",
    NOT_LOADING:            "BACKOFFICE_NOT_LOADING",
    FETCH_HEADER:           "GET_HEADER_BACKOFFICE",
    FETCH_HEADER_SUCCESS:   "GET_HEADER_BACKOFFICE_SUCCESS",
    FETCH_HEADER_FAILURE:   "GET_HEADER_BACKOFFICE_FAILURE",
    FETCH_FOOTER:           "GET_FOOTER_BACKOFFICE",
    FETCH_FOOTER_SUCCESS:   "GET_FOOTER_BACKOFFICE_SUCCESS",
    FETCH_FOOTER_FAILURE:   "GET_FOOTER_BACKOFFICE_FAILURE",
    FETCH_PAGINAS:          "GET_PAGINAS_BACKOFFICE",
    FETCH_PAGINAS_SUCCESS:  "GET_PAGINAS_BACKOFFICE_SUCCESS",
    FETCH_PAGINAS_FAILURE:  "GET_PAGINAS_BACKOFFICE_FAILURE",
    FETCH_NOTICIAS:          "GET_NOTICIAS_BACKOFFICE",
    FETCH_NOTICIAS_SUCCESS:  "GET_NOTICIAS_BACKOFFICE_SUCCESS",
    FETCH_NOTICIAS_FAILURE:  "GET_NOTICIAS_BACKOFFICE_FAILURE",
};

export const GET_PORTADA_BACKOFFICE = "GET_PORTADA_BACKOFFICE"
export const GET_PORTADA_BACKOFFICE_SUCCESS = "GET_PORTADA_BACKOFFICE_SUCCESS"
export const GET_PORTADA_BACKOFFICE_FAILURE = "GET_PORTADA_BACKOFFICE_FAILURE"

export const FETCH_FAQS = "faqs/fetch_all"
export const GET_FAQS_BACKOFFICE = "GET_FAQS_BACKOFFICE"
export const GET_FAQS_BACKOFFICE_SUCCESS = "GET_FAQS_BACKOFFICE_SUCCESS"
export const GET_FAQS_BACKOFFICE_FAILURE = "GET_FAQS_BACKOFFICE_FAILURE"

export const GET_PAGINAINSTITUCIONAL_BACKOFFICE = "GET_PAGINAINSTITUCIONAL_BACKOFFICE"
export const GET_PAGINAINSTITUCIONAL_BACKOFFICE_SUCCESS = "GET_PAGINAINSTITUCIONAL_BACKOFFICE_SUCCESS"
export const GET_PAGINAINSTITUCIONAL_BACKOFFICE_FAILURE = "GET_PAGINAINSTITUCIONAL_BACKOFFICE_FAILURE"

export const GET_NOTICIA_BACKOFFICE = "GET_NOTICIA_BACKOFFICE"
export const GET_NOTICIA_BACKOFFICE_SUCCESS = "GET_NOTICIA_BACKOFFICE_SUCCESS"
export const GET_NOTICIA_BACKOFFICE_FAILURE = "GET_NOTICIA_BACKOFFICE_FAILURE"
