import CryptoJS from 'crypto-js';
import { NotificationManager } from 'react-notifications';

export const redirectOAuthRegister = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    let tujm = urlParams.get('tujm');

    let url = `${process.env.REACT_APP_BASE_URL_OAUTH}/oauth/authorize` // 'https://sso.juanamanso.espinlabs.com.ar/oauth/authorize';
    let code_verifier = generateCodeVerifier();
    document.cookie = "code_verifier=" + code_verifier;
    let code_challenge = generateCodeChallenge(code_verifier);
    let auxUrl=`${url}?client_id=${process.env.REACT_APP_CLIENT_ID_OAUTH}&redirect_url=${process.env.REACT_APP_CALLBACK_OAUTH}&scope=&response_type=code&state=asdasd&code_challenge=${code_challenge}&code_challenge_method=S256&tujm=${tujm}`;
    window.location.href = auxUrl;
}

export const redirectOAuth = () => {
    let url = `${process.env.REACT_APP_BASE_URL_OAUTH}/oauth/authorize` // 'https://sso.juanamanso.espinlabs.com.ar/oauth/authorize';
    let code_verifier = generateCodeVerifier();
    document.cookie = "code_verifier=" + code_verifier;
    let code_challenge = generateCodeChallenge(code_verifier);
    window.location.href = `${url}?client_id=${process.env.REACT_APP_CLIENT_ID_OAUTH}&redirect_url=${process.env.REACT_APP_CALLBACK_OAUTH}&scope=&response_type=code&state=asdasd&code_challenge=${code_challenge}&code_challenge_method=S256`;
}

const fetchToken = (formData) => (
    //https://sso.juanamanso.espinlabs.com.ar/oauth/token'
    fetch(`${process.env.REACT_APP_BASE_URL_OAUTH}/oauth/token`, {
        method: 'POST',
        body: formData,
        headers: {
            "Accept": "application/json",
        },
        credentials: 'include',
    })
    .then(response => response.json())
);

export const getTokenUser = async (urlParams) => {
    const code_verifier = getCookie('code_verifier'); // Recuperar de cookie
    const code = urlParams.get('code'); // Recuperar del request / url

    if(!code_verifier || !code)
        return null;

    const formData = new FormData();
    formData.append("grant_type", "authorization_code");
    formData.append("client_id", process.env.REACT_APP_CLIENT_ID_OAUTH);
    formData.append("redirect_url", process.env.REACT_APP_CALLBACK_OAUTH);
    formData.append("code_verifier", code_verifier);
    formData.append("code", code);

    return await fetchToken(formData)
        .catch(function(error) {
            console.error('Error al Loguearse:', error);
        });
}

export const refreshTokenUser = async () => {
    const refresh_token = getCookie('refresh_token'); // Recuperar de cookie

    if(!refresh_token)
        return null;

    const formData = new FormData();
    formData.append("grant_type", "refresh_token");
    formData.append("client_id", process.env.REACT_APP_CLIENT_ID_OAUTH);
    formData.append("redirect_url", process.env.REACT_APP_CALLBACK_OAUTH);
    formData.append("refresh_token", refresh_token);

    return await fetchToken(formData)
        .then(function(response){
            return response;
        })
        .catch(function(error) {
            console.error('Error al refrescar token:', error);
            localStorage.clear();
            return null;
        });
}

export const logout=(history)=>{
    const arr = window.location.hostname.split('.');
    const domain = arr.slice(Math.max(arr.length - 3, 0)).join('.');
    cleanCookie("logged_in", domain);
    window.location.href=`${process.env.REACT_APP_BASE_URL_OAUTH}/salir?post_logout_redirect_uri=${process.env.REACT_APP_CALLBACK_LOGOUT}`
    // https://sso.juanamanso.espinlabs.com.ar/salir?post_logout_redirect_uri=https://www.google.com/
    NotificationManager.success("Sesión Cerrada!") //habria que verlo, ya que se muestra antes del redirect y no se porque, y de otra manera no se puede hacer jaja
}

function generateCodeVerifier() {
    return generateRandomString(128);
}

function generateRandomString(length) {
    let text = "";
    let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~";
    for (let i = 0; i < length; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
}

function generateCodeChallenge(code_verifier) {
    return base64URL(CryptoJS.SHA256(code_verifier));
}

function base64URL(string) {
    return string.toString(CryptoJS.enc.Base64).replace(/=/g, '').replace(/\+/g, '-').replace(/\//g, '_')
}

export function setCookie(cName, cValue, expDays, domain) {
    let date = new Date();
    date.setTime(date.getTime() + (expDays * 24 * 60 * 60 * 1000));
    const expires = "expires=" + date.toUTCString();

    document.cookie = cName + "=" + cValue + "; " + expires + "; domain= "+ domain + ";path=/";
}

export function getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

export const cleanCookie = (cName, domain = window.location.hostname.split('.').slice(-3).join('.')) => setCookie(cName, "", -5, domain);
export const hasCookie = (cName) => Boolean(getCookie(cName) !== "");
export const isLoguedIn = (reducer) => Boolean(reducer.user_logued !== null);
export const isLoggedIn = () => hasCookie('logged_in');