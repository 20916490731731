import axios from 'axios';
import { json as headersBackoffice } from '../config/headers';

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_JM_STATIC_DOMAIN,
    timeout: 60000,
    headers: headersBackoffice
});

axiosInstance.interceptors.request.use(
    request => requestHandler(request)
);

axiosInstance.interceptors.response.use(
    response => successHandler(response),
    error => errorHandler(error)
);

const requestHandler = (request) => request;
const errorHandler = (error) => Promise.reject({...error});
const successHandler = (response) => response;

export default axiosInstance;
