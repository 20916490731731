import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { NotificationContainer } from 'react-notifications';
import { Route, Switch } from 'react-router-dom';

import { BackofficeService } from 'src/axios/index';
import { getDataUser } from 'src/redux/actions/authActions';
import { getTokenUser } from 'src/helpers/authHelper';

import parseHtml from 'html-react-parser';

import routes from "src/routes/index";
import AppLayout from 'src/layouts/AppLayout';
import NotFound from 'src/layouts/errors/NotFound';

import Mantenimiento from 'src/components/mantenimiento/mantenimiento';
import Portada from 'src/components/backoffice/Portada';
import PaginaInstitucional from 'src/components/backoffice/PaginaInstitucional';
import Noticia from 'src/components/backoffice/Noticia';
import { showDeveloperMessage } from 'src/helpers';
// import ScrollToTop from 'src/components/shared/ScrollToTop'

const AppContainer = props => {
   const { history, location, match, backoffice, userFetch } = props;

   useEffect(() => {
      BackofficeService.fetchTagScripts()
         .then(response => {
            parseHtml(response.data.javascript, {
               replace: function(domNode){
                  if(domNode.type === 'script'){
                     let script = document.createElement('script');

                     if(domNode.attribs?.src){
                        script.setAttribute('async', '');
                        script.src = domNode.attribs.src;
                     }

                     if(domNode.children?.length){
                        script.innerHTML = domNode.children[0].data;
                     }
                     document.head.appendChild(script);
                  }
               }
            })
         });
      //return () => document.body.removeChild(scriptHTML);
   }, []);

   useEffect(() => {
      userFetch();
   }, [userFetch]);

   useEffect(() => {
      const urlParams = new URLSearchParams(location.search);

      const fetchToken = async () => {
         // eslint-disable-next-line no-unused-vars
         const token = await getTokenUser(urlParams);
         userFetch();
         history.push(location.pathname);
      }

      if (urlParams.has('code')) {
         fetchToken();
      }
   }, [location, history, userFetch]);

   useEffect(() => {
      window.scrollTo(0, 0);
   }, [location]);

   useEffect(() => {
      showDeveloperMessage('env', process.env);
   }, []);

   return (
         <React.Fragment>
            <NotificationContainer/>
            <AppLayout>
               <Switch>
                  {
                     backoffice.institucionales && (backoffice.institucionales ?? []).map((pagina, key) => (
                        <Route exact key={key} path={`/${pagina.slug}`}>
                           <PaginaInstitucional pagina={pagina} history={history}
                              match={match} />
                        </Route>
                     ))
                  }
                  {
                     backoffice.portadas && (backoffice.portadas ?? []).map((portada, key) => (
                        <Route exact key={key} path={`/${portada.slug}`}>
                           <Portada componentes={portada.components}
                              history={history}
                              match={match}
                           />
                        </Route>
                     ))
                  }
                  {
                     backoffice.noticias && (backoffice.noticias ?? []).map((noticia, key) =>  (
                        <Route key={key} path={`${match.path}${noticia.slug}`}>
                           <Noticia noticia={noticia}
                              history={history}
                              match={match}
                           />
                        </Route>
                     ))
                  }
                  {
                     routes && routes.map((route, key) => (
                        <Route exact key={key} path={`/${route.path}`} component={route.component} />
                     ))
                  }
                  <Route exact path="/mantenimiento" component={Mantenimiento} />
                  <Route component={NotFound} />
               </Switch>
            </AppLayout>
         </React.Fragment>
   );
}

const mapStateToProps = ({ backoffice }) => ({
   backoffice: backoffice,
});

const mapDispatchToProps = {
   userFetch: getDataUser
}

export default connect(mapStateToProps, mapDispatchToProps)(AppContainer);
