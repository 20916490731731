import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { getPortadaDataBackoffice } from 'src/redux/actions/backofficeActions';

import Loading from 'src/components/shared/loading';
import Portada from 'src/components/backoffice/Portada';

const PreviewPortadasContainer = props => {
    const { match, previewPortadaReducer, history } = props;

    useEffect(()=>{
        props.getPortadaDataBackoffice(match.params.portadaId)
        // eslint-disable-next-line
    }, [])

    if (!previewPortadaReducer.portada) {
		return <Loading />
	}
    return (
        <Portada
            componentes={previewPortadaReducer.portada.components}
            history={history}
        />
    );
}

const mapStateToProps = ({ authReducer, previewPortadaReducer }) => {
    return {
        authReducer,
        previewPortadaReducer
    };
};

const mapDispatchToProps = {
    getPortadaDataBackoffice
}

export default connect(mapStateToProps, mapDispatchToProps)(PreviewPortadasContainer);
