import React, { useState } from 'react';
import ReactHtmlParser from 'react-html-parser'
import { Collapse } from 'reactstrap';

import BreadCrumb from '../shared/breadcrumb';

const Collapsable = (props) => {
    const {pregunta, children} = props;
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);
    return(
        <div className="card mb-3">
            <div className="faq-header" id="headingOne">
                <div style={{cursor: "pointer"}} className="mb-0" onClick={()=>toggle()}>
                    {pregunta}
                </div>
            </div>
            <Collapse isOpen={isOpen}>
                <div className="card-body">
                    {children}
                </div>
            </Collapse>
        </div>
    )
}

const FAQ = props => {
    const { history, match, faqs } = props;

    return (
        <div className="main_content">
            <div className="container-fluid">
                <div className="col-xl-8 offset-xl-2 col-12">
                    <BreadCrumb match={match} history={history} actualPosition={'PREGUNTAS FRECUENTES'} previousPositions={['Inicio']} />
                    <div className="row pb-2">
                        <div className="col-md-10 offset-md-1 text-center">
                            <h1 className="tc-5">Preguntas <span className="tc-5">Frecuentes</span></h1>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-xl-8 offset-xl-2 col-12 mb-5">
                <div className="container">
                    {
                        // eslint-disable-next-line
                        faqs.map(faq => {
                            if(faq.question_and_answers.length > 0){
                                return (
                                    <div className="col-md-12 " style={{ fontSize: 18 }} key={faq.order}>
                                        <h4 className="mb-3 mt-4  tc-3 font-weight-bold">{faq.title}</h4>
                                        {
                                            faq.question_and_answers.map(question_answer => {
                                                return (
                                                    <Collapsable key={question_answer.id} pregunta={ReactHtmlParser(question_answer?.question ?? '')}>
                                                        {ReactHtmlParser(question_answer?.answer ?? '')}
                                                    </Collapsable>
                                                )
                                            })
                                        }
                                    </div>
                                )
                            }
                            
                        })
                    }

                </div>
            </div>
        </div>
    );
}

export default FAQ;
