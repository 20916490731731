import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';

const ExternalRedirect = ({newLocation}) => {
    useEffect(() => {
        window.location = newLocation
    }, [newLocation])

    return <></>
}

const RedirectRecursoBuggy = () => {
    useEffect(() => {
        setTimeout(() => {
            window.location = `${process.env.REACT_APP_REA}${window.location.pathname}`
        }, 100);
    }, []);

    return <></>
}

export default [
    {
        path: "/home",
        redirect: <Redirect to="inicio" />
    },
    {
        path: "/",
        redirect: <Redirect to="inicio" />
    },
    {
        path: "/recursos",
        redirect: <ExternalRedirect newLocation={`${process.env.REACT_APP_REA}/recursos${window.location.search}`} />
    },
    {
        path: "/recurso/:recursoId",
        redirect: <RedirectRecursoBuggy />
    },
    {
        path: "/mis-recursos",
        redirect: <ExternalRedirect newLocation={`${process.env.REACT_APP_REA}/mis-recursos`} />
    },
    {
        path: "/mis-aulas",
        redirect: <ExternalRedirect newLocation={`${process.env.REACT_APP_REA}/mis-aulas`} />
    },
]

