import React from 'react';
import ReactHtmlParser from 'react-html-parser';

const DestacadoSinImagen = ({ componente }) => {
    const background = {
        color: {
            backgroundColor: componente.page_background.color_hex
        }
    };

    const hoverColor = componente.json_data?.hover_color || "";
    const hoverTexto = componente.json_data?.hover_text || "";

    const handleMouseOver = () => {
        const element = document.getElementById(componente.id);
        element.style.backgroundColor = hoverColor;

        // Cambiar el color del texto para los <p> y <span> dentro del div
        const textElements = element.querySelectorAll('p, span');
        textElements.forEach(el => {
            // Capturar el color original, ya sea en línea o computado
            if (!el.dataset.originalColor) {
                el.dataset.originalColor = el.style.color || window.getComputedStyle(el).color;
            }
            el.style.setProperty('color', hoverTexto, ); 
        });
    };

    const handleMouseLeave = () => {
        const element = document.getElementById(componente.id);
        element.style.backgroundColor = background.color.backgroundColor;

        // Restaurar el color original de los <p> y <span>
        const textElements = element.querySelectorAll('p, span');
        textElements.forEach(el => {
            if (el.dataset.originalColor) {
                el.style.setProperty('color', el.dataset.originalColor,); 
            }
        });
    };

    return (
        <a href={componente.url ? componente.url : null} className="card-link" target="_self" rel="noopener noreferrer">
            <div
                className={"card p-4 bg-card shadow-none " + (componente.size > 1 ? "jm-bgk-img p-1" : "jm-bgk-img2 p-10-0")}
                style={background[componente.page_background.type]}
                onMouseOver={handleMouseOver}
                onMouseLeave={handleMouseLeave}
                id={componente.id}
            >
                <div>
                    {ReactHtmlParser(componente.title ? componente.title.replace(/!important/g, "") : '')}
                    {ReactHtmlParser(componente.copete ? componente.copete.replace(/!important/g, "") : '')}
                </div>
                <div className="d-flex align-items-center pt-3" style={{ borderTop: "2px solid" }}>
                    Ver más
                </div>
            </div>
        </a>
    );
};

export default DestacadoSinImagen;
