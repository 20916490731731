import { UserTypes } from "../types";
import { AuthService, BackofficeService } from "src/axios";
import { hasCookie, cleanCookie } from "src/helpers/authHelper";
import auth from 'src/util/mocks/auth';

export const ActionCreators = {
    userLoading: () => ({ type: UserTypes.LOADING }),
    userNotLoading: () => ({ type: UserTypes.NOT_LOADING }),
    userFetchSuccess: (data) => ({ type: UserTypes.FETCH_SUCCESS, payload: data }),
    userFetchFailure: (error) => ({ type: UserTypes.FETCH_FAILURE, payload: error }),
    userMenu: (data) => ({ type: UserTypes.SET_MENU, payload: data })
};

export const getDataUser = () => async (dispatch) =>{
    if(!hasCookie("logged_in"))
        return;

    dispatch(ActionCreators.userLoading());

    const menu = await BackofficeService.fetchUserMenu()
                            .catch((error) => auth.menu);

    try {
        const user = await AuthService.getAuthUser()
                                .then((res) => res.data);

        dispatch(ActionCreators.userFetchSuccess(user));
        dispatch(ActionCreators.userMenu(menu ?? auth.userMenu));
    } catch(error) {
        cleanCookie('logged_in');
        dispatch(ActionCreators.userFetchFailure(error));
    } finally {
        dispatch(ActionCreators.userNotLoading());
    }
}

/* 
        if(error.response && error.response.status === 401) {
            let aux = await refreshTokenUser(AuthService.getAuthUser);
            if(aux !== null){
                const result = await AuthService.getAuthUser();
                dispatch({
                    type: GET_USER_SUCCESS,
                    payload: result.data
                })
            }else{
                dispatch({
                    type: GET_USER_FAILURE
                })
            }
        } else {
            dispatch({
                type: GET_USER_FAILURE
            })
        }
 */
        // if(notRedirect===null){
        //     history.push("/home")
        //     NotificationManager.error("Debes estar logueado para continuar!")
        // }