import axios from 'axios';
import rea from '../api/rea';

const headersBackoffice={
    'Content-Type': 'application/json',
    'Accept': 'application/json'
}
const AuxService = {
    sendMail:(data)=>{
        rea.post('/contact', data);
    },
    getHeaderMenu:()=>{
        return axios.get(`${process.env.REACT_APP_JM_STATIC_DOMAIN}/header/menu.json?${Date.now()}`, {
            headers: headersBackoffice
        })
    },
    getFooterMenu:()=>{
        return axios.get(`${process.env.REACT_APP_JM_STATIC_DOMAIN}/footer/menu.json?${Date.now()}`, {
            headers: headersBackoffice
        })
    },
    getFooterText:()=>{
        return axios.get(`${process.env.REACT_APP_JM_STATIC_DOMAIN}/footer/texto.json?${Date.now()}`, {
            headers: headersBackoffice
        })
    },
    getContactText:()=>{
        return axios.get(`${process.env.REACT_APP_JM_STATIC_DOMAIN}/contact/contact.json?${Date.now()}`, {
            headers: headersBackoffice
        })
    },
    getLogoFooter:()=>{
        return axios.get(`${process.env.REACT_APP_JM_STATIC_DOMAIN}/footer/logo.json?${Date.now()}`, {
            headers: headersBackoffice
        })
    },
    getLogoHeader:()=>{
        return axios.get(`${process.env.REACT_APP_JM_STATIC_DOMAIN}/header/logo.json?${Date.now()}`, {
            headers: headersBackoffice
        })
    },
    getPortadasDataBackoffice:()=>{
        return axios.get(`${process.env.REACT_APP_JM_STATIC_DOMAIN}/portadas/index.json?${Date.now()}`, {
            headers: headersBackoffice
        })
    },
    getPortadaDataBackoffice:(portadaId)=>{
        return axios.get(`${process.env.REACT_APP_JM_STATIC_DOMAIN}/portadas/${portadaId}.json?${Date.now()}`, {
            headers: headersBackoffice
        })
    },
    getFAQS:()=>{
        return axios.get(`${process.env.REACT_APP_JM_STATIC_DOMAIN}/faqs/faq.json?${Date.now()}`, {
            headers: headersBackoffice
        })
    },
    getSitemap:()=>{
        return axios.get(`${process.env.REACT_APP_BASE_URL_API}/sitemap.xml`, {
            headers: headersBackoffice
        })
    },
    getPaginasInstitucionalesDataBackoffice:()=>{
        return axios.get(`${process.env.REACT_APP_JM_STATIC_DOMAIN}/paginas_institucionales/index.json?${Date.now()}`, {
            headers: headersBackoffice
        })
    },
    getPaginaInstitucionalDataBackoffice:(paginaId)=>{
        return axios.get(`${process.env.REACT_APP_JM_STATIC_DOMAIN}/paginas_institucionales/${paginaId}.json`, {
            headers: headersBackoffice
        })
    },
    getTempPaginaInstitucionalDataBackoffice:(paginaId)=>{
        return axios.get(`${process.env.REACT_APP_JM_STATIC_DOMAIN}/paginas_institucionales/tmp/${paginaId}.json`, {
            headers: headersBackoffice
        })
    },
    getNoticiasDataBackoffice:(noticiaId)=>{
        return axios.get(`${process.env.REACT_APP_JM_STATIC_DOMAIN}/noticias/${noticiaId}.json`, {
            headers: headersBackoffice
        })
    },
    getTempNoticiasDataBackoffice:(noticiaId)=>{
        return axios.get(`${process.env.REACT_APP_JM_STATIC_DOMAIN}/noticias/tmp/${noticiaId}.json`, {
            headers: headersBackoffice
        })
    },
    getTagScriptsBackoffice:()=>{
        return axios.get(`${process.env.REACT_APP_JM_STATIC_DOMAIN}/visitor_register/index.json`, {
            headers: headersBackoffice
        })
    },
    getUserMenu:()=>{
        return axios.get(`${process.env.REACT_APP_JM_STATIC_DOMAIN}/user_panel/menu.json?${Date.now()}`, {
            headers: headersBackoffice
        })
    }
}

export {AuxService as default};