import React from "react";
import ReactHtmlParser from 'html-react-parser';
import { ComponenteAgrupadorItem } from "./ComponenteAgrupadorItem";

export function ComponenteAgrupador({ componente })
{
    const title = ReactHtmlParser(componente.title ?? '');
    const listItems = componente.json_data.listItems;
    const bgColor = componente.json_data.title_bg_color;
    const textColor = "#FFFFFF";

    return (
<div className="componente-agrupador">
    <div className="componente-agrupador-header" style={{
        '--bg-color': bgColor,
        '--text-color': textColor,
    }}>
        <div className="componente-agrupador-header-title">
            { componente.url ? <a href={componente.url}>{title}</a> : title }
        </div>
    </div>
    <div className="componente-agrupador-content">
        { listItems.map((item, index) =>
        {
            return <ComponenteAgrupadorItem key={index} item={item}/>
        })}
    </div>
</div>
    )
}
