import React from 'react';
import ReactHtmlParser from 'react-html-parser';

const Noticia = ({ componente }) => {
    const { title, copete, publication_date, url, image_file, page_background } = componente;

    const backgroundURL = `${process.env.REACT_APP_BASE_URL_API}/${image_file.filename}`
    const iconStyles = { ...JSON.parse(image_file.styles), backgroundImage: `url(${backgroundURL})`, height: '9.6em', width: '100%', backgroundSize: 'cover', borderRadius: '1rem 1rem 0rem 0rem' }

    const date = (new Date(publication_date))
        .toLocaleDateString('es-ar', {
            year: "numeric",
            month: "long",
            day: "numeric",
            calendar: 'iso8601',
            timeZone: "America/Buenos_Aires"
        }
    );

    const hoverColor = componente.json_data?.hover_color || "";
    const hoverTexto = componente.json_data?.hover_text || "";

    const handleMouseOver = () => {
        const element = document.getElementById(componente.id);
        element.style.backgroundColor = hoverColor;

        // Cambiar el color del texto para los <p> y <span> dentro del div
        const textElements = element.querySelectorAll('p, span');
        textElements.forEach(el => {
            // Capturar el color original, ya sea en línea o computado
            if (!el.dataset.originalColor) {
                el.dataset.originalColor = el.style.color || window.getComputedStyle(el).color;
            }
            el.style.setProperty('color', hoverTexto, ); // Aplicar color con !important
        });
    };

    const handleMouseLeave = () => {
        const element = document.getElementById(componente.id);
        element.style.backgroundColor = page_background.color_hex;

        // Restaurar el color original de los <p> y <span>
        const textElements = element.querySelectorAll('p, span');
        textElements.forEach(el => {
            if (el.dataset.originalColor) {
                el.style.setProperty('color', el.dataset.originalColor,); // Restaurar color original con !important
            }
        });
    };    

    const allParagraphs = ReactHtmlParser(copete ? copete.replace(/!important/g, "") : '');

    return (
        <div
            className="card noticia-card"
            style={{ backgroundColor: page_background.color_hex || "white" }}
            onMouseOver={handleMouseOver}
            onMouseLeave={handleMouseLeave}
            id={componente.id}
        >
            <a href={url?? '#REA'} className="noticia-link" target="_self" rel="noopener noreferrer">
                <div className="noticia-img">
                    <div className="icono-home-xl" style={iconStyles}/>
                </div>
                <div className='p-4'>
                    <div className="noticia-body">
                        <div className='noticia-title' id={`noticia-title-${componente.id}`}>
                            {ReactHtmlParser(title ? title.replace(/!important/g, "") : '')}
                            
                        </div>
                        <div className='noticia-copete' id={`noticia-copete-${componente.id}`}>
                            {allParagraphs.length > 0 && allParagraphs}
                          
                        </div>
                    </div>
                    <div className="noticia-footer pt-4">
                        <span className='noticia-date'>{date}</span>
                    </div>
                </div>
            </a>
        </div>
    );
};

export default Noticia;
