import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Dropdown, DropdownMenu, DropdownItem } from 'reactstrap';
import { getDomain } from 'src/helpers';

export const HeaderDropdown = (props) => {
    const { name, onItemClick } = props;
    const history = useHistory();
    
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen(prevState => !prevState);

    return(
        <Dropdown isOpen={dropdownOpen} toggle={toggle}>
{/*             <DropdownToggle className="nav-link cool-link">
                <span 
                    className="text-white" 
                    style={{ color: "rgb(255, 255, 255) °important", fontSize: "0.875rem", maxWidth: "150px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
                >
                    {name}
                </span>
                <i className="fa fa-caret-down ml-1"></i>
            </DropdownToggle> */}
            <a className="nav-link cool-link" href="#REA" onClick={(event) => {
                event.preventDefault();
                toggle();
                }}><span>{name}</span><i className="fa fa-caret-down ml-1"></i>
            </a>
            <DropdownMenu
                style={{ overflowX: 'hidden', overflowY: 'hidden', left: 0, right: 'auto', width: 'auto' }}
            >
            {
                props.children.map(child => {
                return(
                    <a key={child.id} href={(child.link_type === 'url') ? `${child.link}` : `${window.location.origin}/${child.slug}`}>
                        <DropdownItem
                            key={child.id}
                            className="dropdownItemHeader"
                            onClick={(event) => {
                                if(child.link_type !== 'url'){
                                    event.preventDefault();
                                    onItemClick();
                                    history.push(child.slug);
                                } else {
                                    getDomain(child.link,event);
                                }
                            }}
                        >
                                {child.name}
                        </DropdownItem>
                    </a>
                    )
                })
            }
            </DropdownMenu>
        </Dropdown>
    )
}