import React from 'react';
import ReactHtmlParser from 'react-html-parser';

const Embebido = ({ componente }) => {
  return (
    <div
        className={"card shadow-none " + (componente.size > 1 ? "jm-bgk-img embed-responsive embed-responsive-4by3 p-0 p-lg-1" : "jm-bgk-img2 p-10-0")}
    >
        {
            /* Para que quieren el src? No hay por qué */
/*             componente.url ? (
                <iframe title={componente.id} src={componente.url}></iframe>
            ) : ( */
                ReactHtmlParser(componente.embedded)
            // )
        }
    </div>
  )
}

export default Embebido