import React, { useState } from 'react'
import Slider from "react-slick";
import {isMobile} from 'react-device-detect';

import { CircularProgress } from '@material-ui/core';

import CarouselCard from './recurso-cards/carousel-card';


const SliderArrow = ({className, classes, styles, to, onClick}) =>
    <button
        aria-label={to}
        className={`button button--text button--icon ${classes} ${className}`}
        style={{...styles}}
        type="button" onClick={onClick}
    >
        <div className="icono" icon={to} />
    </button>

const activeDot = {
    height: "10px",
    width: "10px",
    backgroundColor: "#1AC2C2"
};

const inactiveDot = {
    height: "8px",
    width: "8px",
    backgroundColor: "white"
};

const CarouselSearch = (props) => {
    const { searchResults, loadingSearchResults } = props;
    const [state, setState] = useState({ currentSlide: 0 });

    if (searchResults === null || loadingSearchResults) {
        return (
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <CircularProgress />
            </div>

        )
    }
    if (searchResults.length === 0) {
        return (
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <p>No se han encontrado resultados para su búsqueda</p>
            </div>
        )
    }
    return (
        <Slider
            arrows={true}
            dots={true}
            appendDots={dots => (
                <div
                    style={{
                        paddingTop: "20px"
                    }}
                >
                    <ul className="carousel-dots"> {dots} </ul>
                </div>
            )}
            beforeChange={(prev, next) => {
                setState({ currentSlide: next });
            }}
            customPaging={i => (
                <div
                    style={Object.assign(
                    {
                        borderRadius: "50%",
                        display: "inline-block",
                        border: "2px grey solid",
                    },
                    i === state.currentSlide / 2 ? activeDot : inactiveDot
                )}
                >
                </div>
            )}
            infinite={true}
            speed={500}
            slidesToShow={(searchResults.length === 1 || isMobile) ? 1 : 2}
            slidesToScroll={2}
            autoplay={true}
            autoplaySpeed={2500}
            className="height-carousel"
            nextArrow={<SliderArrow classes="carousel-control-next-icon dark" styles={{marginRight: "25px"}} to="next" />}
            prevArrow={<SliderArrow classes="carousel-control-prev-icon dark" styles={{marginLeft: "25px"}} to="prev" />}
        >
            {

                searchResults.map((element, index) => 
                    <div
                        className="row"
                        key={index}
                    >
                        <div className={searchResults.length === 1 ? "col-sm-6" : "col-sm-12"}>
                            <CarouselCard
                                recurso={element}
                            />
                        </div>
                    </div>
                )
            }
            {
                searchResults.length === 2 &&
                <div className="col-sm-12"></div>
            }
        </Slider>
    )
}

export default CarouselSearch;
