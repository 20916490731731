import React from "react";
import { useRouteMatch } from "react-router-dom";
import ReactHtmlParser from "html-react-parser";

import { showDeveloperMessage } from "src/helpers";
import UserMenu from "src/components/backoffice/usuario/UserMenu";
import Logo from "src/components/backoffice/Logo";
import NavBar from "./header/NavBar";

const Header = ({ options, logo, headerTexto }) => {
  const matchMantenimiento = useRouteMatch("/mantenimiento");
  const matchPreviewFooter = useRouteMatch("/preview/footer");

  const openMenu = () => {
    let menu = document.getElementById("navbarMain");
    if (menu.classList.contains("show")) {
      menu.classList.remove("show");
    } else {
      menu.classList.add("show");
    }
  };

  const closeMenu = () => {
    let menu = document.getElementById("navbarMain");
    if (menu.classList.contains("show")) {
      menu.classList.remove("show");
    }
  };

  React.useEffect(() => {
    showDeveloperMessage("header", { logo, menu: options });
  }, [options, logo]);

  return (
    !matchPreviewFooter && (
      <header>
        <div className="wrapper-header container mx-auto fixed-top m-0">
          <nav className="wrapper-logo navbar navbar-expand-lg p-0 m-0">
            <div className="d-flex justify-content-between w-100 pl-lg-5 pb-lg-4 pt-lg-4">
              <div id="header-logo">
                <Logo className="logo-header" logo={logo} target="_self" />
              </div>
              <div id="header-texto">
                <span className="header-texto-circulo"> </span>
                  {ReactHtmlParser(headerTexto?.text ?? "")}
                <span className="header-texto-circulo"> </span>
              </div>
              <div id="header-fin"></div>
            </div>
          </nav>
          <nav className="navbar navbar-expand-lg navbar-dark dark p-1 m-0">
            {!matchMantenimiento && (
              <>
                <button
                  className="navbar-toggler"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarMain"
                  aria-controls="navbarMain"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                  onClick={() => {
                    openMenu();
                  }}
                >
                  <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarMain">
                  <div className="d-flex  justify-content-end w-100">
                    <div></div>
                    <div className="mr-4 wrapper-nav-items">
                      <NavBar closeMenu={closeMenu} options={options} />
                    </div>
                    {/* <div className="wrapper-userMenu">
                      <UserMenu />
                    </div> */}
                  </div>
                </div>
              </>
            )}
          </nav>
        </div>
      </header>
    )
  );
};

export default Header;

