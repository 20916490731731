import React from 'react';
import ReactHtmlParser from 'html-react-parser';
import BreadCrumb from '../shared/breadcrumb';

const ContactoSuccess = ({ message, match, history }) => {
    return (
        <div className="main_content">
            <div className="container-fluid">
                <div className="col-xl-8 offset-xl-2 col-12">
                    <BreadCrumb match={match} history={history} actualPosition={"Contacto"} previousPositions={['Inicio']} />
                </div>
            </div>
            <div className="col-xl-8 offset-xl-2 col-12 mb-5 mt-5">
                <div className="container mx-0">
                    <div className="col-md-12 px-0">
                        <h1 className="tc-1 mb-0">Contacto</h1>
                        <div className="row"> 
                            <div className="col-12 alert cartel-contacto">{ReactHtmlParser(message ?? '')}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ContactoSuccess;
