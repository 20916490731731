import React from "react";
import botondescarga from  '../../assets/images/botondescarga.png';

export function IconDownload()
{
    return (
        <img  alt="" src={botondescarga} className="pull-right pt-2" style={{ margin: "auto", padding: "0 15px 7px 0", width: "35px", height: "35px" }} /> 
    );
   
}
