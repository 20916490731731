import React from "react";
import { useRouteMatch } from "react-router-dom";
import ReactHtmlParser from "html-react-parser";

import { showDeveloperMessage } from "src/helpers";
import Logo from "src/components/backoffice/Logo";
import FootBar from "./footer/FootBar";
import { Row } from "react-bootstrap";

const Footer = ({ options, text, text2, logo, logo_centro }) => {
  const matchMantenimiento = useRouteMatch("/mantenimiento");
  const matchPreviewHeader = useRouteMatch("/preview/header");
  const hasLogoDerecha = Boolean(logo_centro?.link);

  const logoCentroTarget =
    logo_centro.link &&
    logo_centro.link.endsWith("#") &&
    window.innerWidth > 768
      ? "_blank"
      : "_self";
  const logoTarget =
    logo.link && logo.link.endsWith("#") && window.innerWidth > 768
      ? "_blank"
      : "_self";

  React.useEffect(() => {
    showDeveloperMessage("footer", { logo, menu: options });
  }, [logo, options]);

  return (
    !matchPreviewHeader && (
      <footer className="footer">
        <hr className="footer-linea" />
        <div className="container-fluid">
          <div className="d-flex flex-column flex-lg-row justify-content-between align-items-center mt-5 mb-4">
            <div className="mb-4 mb-lg-2">
              <Logo className="logos-footer" logo={logo} target={logoTarget} />
            </div>
            <div className="mb-3 mb-lg-0">
              <a
                className="redes-sociales mr-2"
                href="https://www.facebook.com/EducarSEar"
                rel="noopener noreferrer"
                target="_blank"
              >
                <i className="fa-brands fa-facebook-f redes-sociales-icono"></i>
              </a>

              <a
                className="redes-sociales mr-2"
                href="https://www.instagram.com/educarsear/"
                rel="noopener noreferrer"
                target="_blank"
              >
                <i className="fa-brands fa-instagram redes-sociales-icono"></i>
              </a>
              <a
                className="redes-sociales mr-2"
                href="https://x.com/EducarseAr"
                rel="noopener noreferrer"
                target="_blank"
              >
                <i className="fa-brands fa-x-twitter redes-sociales-icono"></i> 
              </a>
              <a
                className="redes-sociales mr-2"
                href="https://www.youtube.com/@EducarSE"
                rel="noopener noreferrer"
                target="_blank"
              >
                <i className="fa-brands fa-youtube-play redes-sociales-icono"></i>
              </a>
              <a
                className="redes-sociales mr-2"
                href="https://www.linkedin.com/company/24795165/admin/feed/posts/"
                rel="noopener noreferrer"
                target="_blank"
              >
                <i className="fa-brands fa-linkedin redes-sociales-icono"></i>
              </a>
            </div>
            <div className="text-start ck-content footer-texto p-0 mb-4 mb-lg-0">
              {ReactHtmlParser(text ?? "")}
            </div>
            <div className="text-start ck-content footer-texto mb-4 mb-lg-0">
              {ReactHtmlParser(text2 ?? "")}
            </div>
            {
              hasLogoDerecha &&
              <div>
                <Logo className="logos-footer" logo={logo_centro} target={logoCentroTarget} />
              </div>
            }
          </div>

          <Row className="footer-menu-navegacion d-flex justify-content-center justify-content-lg-end p-4">
            <FootBar options={options} />
          </Row>
        </div>
      </footer>
    )
  );
};
export default Footer;
