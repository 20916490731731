const envDevelop = () => process.env.NODE_ENV && process.env.NODE_ENV === 'development';
const envProduction = () => (process.env.NODE_ENV && process.env.NODE_ENV === 'production') || process.env.NODE_ENV === 'production';

const envProd = envProduction;
const envDev = envDevelop;

const isInMaintenanceMode = () => process.env.REACT_APP_MATENIMIENTO === "true";
const isNotInMaintenanceMode = () => !isInMaintenanceMode();

const isDevice = (devices) => Boolean((navigator.userAgent || navigator.vendor || window.opera).match(devices));

const isMobile = () => isDevice(/Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i);
const isIPhone = () => isDevice(/iPhone|iPad|iPod/i);

export {
    envDevelop,
    envDev,
    envProduction,
    envProd,
    isInMaintenanceMode,
    isNotInMaintenanceMode,
    isMobile,
    isIPhone
}
