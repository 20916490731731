import React from 'react';
import ReactHtmlParser from 'react-html-parser';

const Texto = (props) => (
    <div>
        <div className={"card shadow-none jm-bgk-img p-10-0"}>
                <div
                    className="componenteTexto"
                    style={{color:"black", backgroundColor:"white"}}
                >
                    {ReactHtmlParser((props.componente?.description ?? '').replace(/!important/g, ""))}
                </div>
        </div>
    </div>
);

export default Texto;
