import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { useHistory } from 'react-router-dom';

const PanelCard = ({background, copete, icon, link, link_type, target, name: title, slug}) => {
    const history = useHistory();

    function handleClick(event) {
        event.preventDefault();

        const hostname = window.location.hostname;
        const protocol = window.location.protocol;
        const url = link.split('/');

        if(target === 'blank') {
            window.open(link, '_blank', 'noopener, noreferrer');
        } else {
            if(protocol === url[0] && hostname === url[2]) {
                const route = url.slice(3, url.length).join('/');
                history.push(`/${route}`);
            } else {
                window.location = link;
            }
        }
    }

    function setBackground() {
        if (background.type === 'color') {
            return { backgroundColor: background.data }
        }
        if (background.type === 'image') {
            return { backgroundImage: `url(${process.env.REACT_APP_BASE_URL_API}/${background.data})` }
        }
        return {};
    }

    return (
        <div className="col-md-3 col-12" onClick={handleClick}>
            <a href="#REA" className="panel-card-link">
                <div className={`card bg-card jm2-our jmCard`} style={setBackground()}>
                    <div className="card-body">
                        <div className="row">
                        <div className="col-md-12">
                        <h4 className="font-weight-bold lh-30">{ReactHtmlParser(title ?? '')}</h4>
                        </div>
                            <div className="col-md-12 col-12">
                                <p style={{ fontSize:"15px" }} className="font-weight-light max-ch">{ReactHtmlParser(copete ?? '')}</p>
                            </div>
                            <br/>
                            <div className="col-md-12 col-12" style={{ position: "absolute", bottom: "10%" }}>
                                <div className={`icono-home`} style={{ backgroundImage: `url(${icon})`, height: 75, backgroundPosition: "center initial" }}></div>
                            </div>
                        </div>
                    </div>
                </div>
            </a>
        </div>
    );
}
export default PanelCard;
