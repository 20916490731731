import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Switch, Redirect, Route } from 'react-router-dom';
import { store } from './redux';
import { redirects } from './routes';
import AppContainer from './containers/AppContainer';
import Sitemap from './components/sitemap/sitemap'

import "./assets/css/bootstrap.css";
import "./assets/css/custom.css";
import "./assets/css/iconos.css";
import "./assets/css/noticias.css";
import "fontsource-roboto"
import '../node_modules/font-awesome/css/font-awesome.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';


import "../node_modules/slick-carousel/slick/slick.css";
import "../node_modules/slick-carousel/slick/slick-theme.css";

import 'react-notifications/lib/notifications.css';
import "./assets/css/ckeditor.css";
import './assets/css/project.css';
import './assets/scss/main.scss';

import { redirectOAuthRegister } from './helpers/authHelper';
import { isInMaintenanceMode, isNotInMaintenanceMode } from 'src/helpers';

const RegistroComponent = (props) => {
	useEffect(() => {
		setTimeout(() => {
			redirectOAuthRegister()
		}, 3000)
	}, [])
	return (
		<div className="mt-1 ml-1">
			<p>Estás siendo redirigido a una URL externa...</p>
		</div>
	)
}

const App = () => (
	<Provider store={store}>
		<Router>
			<Switch>
				{isNotInMaintenanceMode() && <Redirect exact from="/mantenimiento" to="/inicio" />}
				{isInMaintenanceMode() &&
					<Route render={(props) => (
						props.location.pathname !== "/mantenimiento"
							? <Redirect to="mantenimiento"/>
							: <AppContainer {...props} />
					)} />
				}
				{
					redirects && redirects.map((route, key) => (
						<Route exact key={key} path={`${route.path}`} render={() => route.redirect} />
					))
				}
				<Route exact path="/sitemap.xml" component={Sitemap}  />
				<Route exact path="/registro" component={RegistroComponent} />
				<Route component={AppContainer} />
			</Switch>
		</Router>
	</Provider>
);

export default App;
