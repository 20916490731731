import React from 'react';
import { mostrarAvisoDeSalida } from 'src/helpers';

const Logo = ({ className, logo , target}) => {
 
    const rel = target === '_blank' ? "noopener noreferrer" : "";
    
    const imgPath = `${process.env.REACT_APP_JM_STATIC_DOMAIN}/${logo.url}?${Date.now()}` + '#svgView(preserveAspectRatio(none))'
    return (
        <a
            href={logo?.link?.endsWith("#") ? logo.link.slice(0,-1) : logo.link}
            target={target}
            onClick={(logo?.link === window.location.origin) ? () => {} : mostrarAvisoDeSalida}
            rel={rel}
        >
            <img
                className={className}
                src={imgPath}
                style={JSON.parse(logo.styles)}
                alt={logo.alt_text}
                //height="100px"
            />
        </a>
    );
}

export default Logo;
