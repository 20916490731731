import React from 'react';
import { useHistory } from 'react-router-dom';

const ErrorLayout = ({
    code,
    message
}) => {
    const history = useHistory();

    function handleClick(event) {
        event.preventDefault();
        history.push("/inicio");
    }

    return (
        <div className="container-fluid" style={{marginTop: "9rem"}}>
            <div className="col-xl-8 offset-xl-2 col-12">
                <div className="card-login2 jm1 shadow-sm text-center">
                    <h2 className="font-weight-bold mt-1 text-white" style={{fontSize: '260px', marginBottom: '0px', lineHeight: '260px'}}>{code}</h2>
                    <p className="mt-0 text-white" style={{ fontSize: "18px" }}>{message}</p>
                    <a href="#REA" className="btn btn-sm btn-primary btn-conectar-igualdad ripple" style={{ fontSize: "18px" }} onClick={handleClick}>VOLVER AL SITIO</a>
                </div>
            </div>
        </div>
    );
}

export default ErrorLayout;
