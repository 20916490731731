export default {
    menu: [],
    logo:
    {
        "url": null,
        "link": null,
        "alt_text": "Logo del Pie de página",
        "styles": "{}"
    },
    logo_centro: {"url": null, "link": null, "alt_text": "", "styles": "{}"},
    text: "<p><span style=\"font-size: 12px; color: rgb(255, 255, 255);\"></span></p>"
}