const decodeEntities = (function () {
    // this prevents any overhead from creating the object each time
    const element = document.createElement('div');

    function decodeHTMLEntities(str) {
        if (str && typeof str === 'string') {
            // strip script/html tags
            element.innerHTML = 
                str
                    .replace(/<script[^>]*>([\S\s]*?)<\/script>/gmi, '')
                    // .replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gmi, '')
            ;
            str = element.textContent;
            element.textContent = '';
        }

        return str;
    }

    return decodeHTMLEntities;
}());

export const stripHTML = (string) => {
    return decodeEntities(string)
        .replace(/&nbsp;/g, ' ')
        .replace(/&amp;/g, '&')
    ;
};
