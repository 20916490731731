import {
    GET_RECURSO_BY_ID,
    GET_RECURSO_BY_ID_SUCCESS,
    GET_RECURSO_BY_ID_FAILURE,
    RecursosTypes
} from '../types';

const INIT_STATE = {
    all: null,
    error: null,
    detalle_recurso: null,
    error_detalle: null,
    loading: false
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case RecursosTypes.LOADING:
            return{
                ...state,
                loading: true
            }
        case RecursosTypes.FETCH_SUCCESS:
            return{
                ...state,
                all: action.payload,
                loading: false
            }
        case RecursosTypes.FETCH_FAILURE:
            return{
                ...state,
                error: action.payload,
                loading: false
            }
        case GET_RECURSO_BY_ID:
            return{
                ...state,
                loading: true
            }
        case GET_RECURSO_BY_ID_SUCCESS:
            return{
                ...state,
                detalle_recurso: action.payload,
                loading: false
            }
            
        case GET_RECURSO_BY_ID_FAILURE:
            return{
                ...state,
                error_detalle: action.payload,
                loading: false
            }
        default: return { ...state };
    }
}
