import {
  GET_PORTADA_BACKOFFICE,
  GET_PORTADA_BACKOFFICE_SUCCESS,
  GET_PORTADA_BACKOFFICE_FAILURE,
  GET_FAQS_BACKOFFICE,
  GET_FAQS_BACKOFFICE_SUCCESS,
  GET_FAQS_BACKOFFICE_FAILURE,
  GET_PAGINAINSTITUCIONAL_BACKOFFICE,
  GET_PAGINAINSTITUCIONAL_BACKOFFICE_SUCCESS,
  GET_PAGINAINSTITUCIONAL_BACKOFFICE_FAILURE,
  GET_NOTICIA_BACKOFFICE,
  GET_NOTICIA_BACKOFFICE_SUCCESS,
  GET_NOTICIA_BACKOFFICE_FAILURE,
  BackofficeTypes,
} from "../types";
import { AuxService, BackofficeService } from "src/axios";
import { showDeveloperError } from "src/helpers";

export const ActionCreators = {
  setHeader: (header) => ({
    type: BackofficeTypes.FETCH_HEADER_SUCCESS,
    payload: header,
  }),
  setFooter: (footer) => ({
    type: BackofficeTypes.FETCH_FOOTER_SUCCESS,
    payload: footer,
  }),
  setPaginas: (paginas) => ({
    type: BackofficeTypes.FETCH_PAGINAS_SUCCESS,
    payload: paginas,
  }),
};

export const getHeaderBackoffice = () => async (dispatch) => {
  dispatch({ type: BackofficeTypes.FETCH_HEADER });
  let menu = [];
  let logo = {};
  let headerTexto = "";

  try {
    menu = await BackofficeService.fetchHeaderMenu();
  } catch (error) {
    //  dispatch({ type: BackofficeTypes.FETCH_HEADER_FAILURE });
    menu = [];
    console.log("Error al obtener la data del header menu: ", error);
  }

  try {
    logo = await BackofficeService.fetchHeaderLogo();
  } catch (error) {
    // dispatch({ type: BackofficeTypes.FETCH_HEADER_FAILURE })
    logo = {
      url: null,
      link: "",
      alt_text: "Logo del Encabezado",
      styles: "{}",
    };
    console.log("Error al obtener la data del header logo: ", error);
  }

  try {
    headerTexto = await BackofficeService.fetchHeaderText();
  } catch (error) {
    // dispatch({ type: BackofficeTypes.FETCH_HEADER_FAILURE })
    headerTexto =
      '<p><span style="font-size: 12px; color: rgb(255, 255, 255);"></span></p>';
    console.log("Error al obtener la data del header texto: ", error);
  }

  dispatch(
    ActionCreators.setHeader({
      menu: menu,
      logo: logo,
      headerTexto: headerTexto,
    })
  );
};

export const getFooterBackoffice = () => async (dispatch) => {
  dispatch({ type: BackofficeTypes.FETCH_FOOTER });

  let menu = [];
  let logo = {};
  let logo2 = {};
  let text = "";
  let text2 = "";

  try {
    menu = await BackofficeService.fetchFooterMenu();
  } catch (error) {
    menu = [];
    console.log("Error al obtener la data del footer menu: ", error);
  }

  try {
    logo = await BackofficeService.fetchFooterLogo();
  } catch (error) {
    logo = {
      url: null,
      link: null,
      alt_text: "Logo del Pie de página",
      styles: "{}",
    };
    console.log("Error al obtener la data del footer logo: ", error);
  }

  try {
    logo2 = await BackofficeService.fetchFooterLogoCentro();
  } catch (error) {
    logo2 = {
      url: null,
      link: null,
      alt_text: "Logo del Pie de página",
      styles: "{}",
    };
    console.log("Error al obtener la data del footer logo2: ", error);
  }

  try {
    text = await BackofficeService.fetchFooterText();
  } catch (error) {
    text =
      '<p><span style="font-size: 12px; color: rgb(255, 255, 255);"></span></p>';
    console.log("Error al obtener la data del footer: ", error);
  }

  try {
    text2 = await BackofficeService.fetchFooterText2();
  } catch (error) {
    text2 =
      '<p><span style="font-size: 12px; color: rgb(255, 255, 255);"></span></p>';
    console.log("Error al obtener la data del footer: ", error);
  }

  dispatch(
    ActionCreators.setFooter({
      menu: menu,
      logo: logo,
      logo_centro: logo2,
      text: text.text,
      text2: text2.text,
    })
  );

  // try {
  //   const menu = BackofficeService.fetchFooterMenu();
  //   const logo = BackofficeService.fetchFooterLogo();
  //   const logo2 = await BackofficeService.fetchFooterLogoCentro().catch(
  //     (error) => console.log(error)
  //   );
  //   const text = BackofficeService.fetchFooterText();
  //   const text2 = BackofficeService.fetchFooterText2();

  // dispatch(
  //   ActionCreators.setFooter({
  //     menu: await menu,
  //     logo: await logo,
  //     logo_centro: await logo2,
  //     text: (await text).text,
  //     text2: (await text2).text,
  //   })
  // );
  // } catch (error) {
  //   dispatch({ type: BackofficeTypes.FETCH_FOOTER_FAILURE });
  //   console.log("Error al obtener la data del footer: ", error);
  // }
};

export const getPaginasBackoffice = () => async (dispatch) => {
  dispatch({ type: BackofficeTypes.FETCH_PAGINAS });
  try {
    const institucionales = BackofficeService.fetchInstitucionales();
    const portadas = BackofficeService.fetchPortadas();
    const noticias = BackofficeService.fetchNoticias();

    dispatch(
      ActionCreators.setPaginas({
        institucionales: await institucionales,
        portadas: await portadas,
        noticias: await noticias,
      })
    );
  } catch (error) {
    dispatch({ type: BackofficeTypes.FETCH_PAGINAS_FAILURE });
    showDeveloperError("Error al obtener las páginas del backoffice: ", error);
  }
};

export const getPortadaDataBackoffice = (portadaId) => async (dispatch) => {
  dispatch({ type: GET_PORTADA_BACKOFFICE });
  try {
    const result = await AuxService.getPortadaDataBackoffice(portadaId);
    dispatch({
      type: GET_PORTADA_BACKOFFICE_SUCCESS,
      payload: result.data,
    });
  } catch (error) {
    dispatch({ type: GET_PORTADA_BACKOFFICE_FAILURE });
    console.log("Error al obtener la data de la portada: ", error);
  }
};

export const getFAQSBackoffice = () => async (dispatch) => {
  dispatch({ type: GET_FAQS_BACKOFFICE });
  try {
    const result = await AuxService.getFAQS();
    dispatch({
      type: GET_FAQS_BACKOFFICE_SUCCESS,
      payload: result.data,
    });
  } catch (error) {
    dispatch({ type: GET_FAQS_BACKOFFICE_FAILURE });
    console.log("Error al obtener la data de las FAQS: ", error);
  }
};

export const getTempPaginaInstitucionalDataBackoffice =
  (paginaId) => async (dispatch) => {
    dispatch({ type: GET_PAGINAINSTITUCIONAL_BACKOFFICE });
    try {
      const result = await AuxService.getTempPaginaInstitucionalDataBackoffice(
        paginaId
      );
      dispatch({
        type: GET_PAGINAINSTITUCIONAL_BACKOFFICE_SUCCESS,
        payload: result.data,
      });
    } catch (error) {
      dispatch({ type: GET_PAGINAINSTITUCIONAL_BACKOFFICE_FAILURE });
      console.log(
        "Error al obtener la data de la página institucional: ",
        error
      );
    }
  };

export const getPaginaInstitucionalDataBackoffice =
  (paginaId) => async (dispatch) => {
    dispatch({ type: GET_PAGINAINSTITUCIONAL_BACKOFFICE });
    try {
      const result = await AuxService.getPaginaInstitucionalDataBackoffice(
        paginaId
      );
      dispatch({
        type: GET_PAGINAINSTITUCIONAL_BACKOFFICE_SUCCESS,
        payload: result.data,
      });
    } catch (error) {
      dispatch({ type: GET_PAGINAINSTITUCIONAL_BACKOFFICE_FAILURE });
      console.log(
        "Error al obtener la data de la página institucional: ",
        error
      );
    }
  };

export const getTempNoticiasDataBackoffice = (paginaId) => async (dispatch) => {
  dispatch({ type: GET_NOTICIA_BACKOFFICE });
  try {
    const result = await AuxService.getTempNoticiasDataBackoffice(paginaId);
    dispatch({
      type: GET_NOTICIA_BACKOFFICE_SUCCESS,
      payload: result.data,
    });
  } catch (error) {
    dispatch({ type: GET_NOTICIA_BACKOFFICE_FAILURE });
    console.log("Error al obtener la data de la noticia: ", error);
  }
};

export const getNoticiasDataBackoffice = (paginaId) => async (dispatch) => {
  dispatch({ type: GET_NOTICIA_BACKOFFICE });
  try {
    const result = await AuxService.getNoticiasDataBackoffice(paginaId);
    dispatch({
      type: GET_NOTICIA_BACKOFFICE_SUCCESS,
      payload: result.data,
    });
  } catch (error) {
    dispatch({ type: GET_NOTICIA_BACKOFFICE_FAILURE });
    console.log("Error al obtener la data de la noticia: ", error);
  }
};
