import oauth from '../api/oauth';
import rea from '../api/jmi';

const AuthService = {
    getAuthUser:()=>{
        return rea.get(`/user`)
    },
    getAulas:()=>{
        return oauth.get(`/api/classroom/access-points`)
    }
}

export {AuthService as default};