import React, { Component } from 'react';
import loading from '../../assets/images/loading.svg';

class Loading extends Component {
  render() {
    const style = {
      display: 'flex',
      alignContent:'flex-start',
      justifyContent: 'center',
      height: this.props.halfHeight ? '50vh':'100vh',
      width: this.props.fullWidth ? '100vw' : 'auto',
      marginTop: this.props.marginTop? 113 : 0,
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      backgroundColor: 'rgb(234, 234, 234)'
    };
    return (
      <div style={style}>
        <img src={loading} alt="loading" width="225px" />
      </div>
    );
  }
}

export default Loading;