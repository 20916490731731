import React, { useEffect, useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import parseHtml from 'html-react-parser';

// Función para convertir HTML a texto sin tags y verificar si el texto es significativo
const htmlToText = (html) => {
  if (!html) return '';
  const div = document.createElement('div');
  div.innerHTML = html;
  const text = div.textContent || div.innerText || '';
  // Elimina espacios en blanco y verifica si hay contenido significativo
  return text.trim() === '' ? '' : text;
};

const DynamicCarousel = ({ componente }) => {
  const [carouselItems, setCarouselItems] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const urlApi = process.env.REACT_APP_BASE_URL_API;
    if (componente && componente.json_data) {
      const carouselItems = componente.json_data.listItems.reduce((acc, item, index) => {
        acc.push({
          order: index,
          src: item.partialFileName ? urlApi + '/' + item.partialFileName : '',
          alt: item.alt_text ? item.alt_text : '',
          color: item.bg_color ? parseHtml(item.bg_color) : 'white',
          captionTitle: item.titulo ? htmlToText(item.titulo) : '',
          captionText: item.copete ? htmlToText(item.copete) : '',
          url: item.enlace ? item.enlace : ''
        });
        return acc;
      }, []);
      setCarouselItems(carouselItems);
    }
    setLoading(false);
  }, [componente, setCarouselItems]);

  if (loading) {
    return <div>Cargando...</div>;
  }

  return (
    <div className="container" style={{ display: 'block', marginBottom: 30, minHeight: 400 }}>
      <Carousel controls={false} className='row'>
        {carouselItems.map((item, index) => (
          <Carousel.Item key={index} interval={1500} style={{ minHeight: 380 }}>
            <img
              className="d-block w-100"
              src={item.src}
              alt={item.alt}
            />
            <Carousel.Caption>
              {item.captionTitle || item.captionText ? (
                item.url ? (
                  <a href={item.url} style={{ color: 'white', textDecoration: 'none' }}>
                    {item.captionTitle && <h3 style={{ backgroundColor: item.color, marginBottom: '0' }}>{item.captionTitle}</h3>}
                    {item.captionText && <h5 style={{ backgroundColor: item.color, marginTop: '5', marginBottom: '0' }} className="slider-copete">{item.captionText}</h5>}
                  </a>
                ) : (
                  <>
                    {item.captionTitle && <h3 style={{ backgroundColor: item.color, marginBottom: '0' }}>{item.captionTitle}</h3>}
                    {item.captionText && <h5 style={{ backgroundColor: item.color, marginTop: '5', marginBottom: '0' }} className="slider-copete">{item.captionText}</h5>}
                  </>
                )
              ) : null}
            </Carousel.Caption>
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  );
}

export default DynamicCarousel;
