import React from 'react';
import BreadCrumbLayout from 'src/layouts/shared/BreadCrumb';
import PanelCard from './PanelCard';

const HomeUsuario = props => {
    const { cards, user } = props;

    const auxCards = [...cards];
    
    const cardRows = [], size = 4;
    while (auxCards.length > 0) cardRows.push(auxCards.splice(0, size));

    return (
		<div className="main_content">
            <BreadCrumbLayout actualPosition={"Mi Panel"} previousPositions={['Inicio']}/>
			<div className="container-fluid pt-3">
                <div className="col-xl-10 offset-xl-1 col-12">
                    <div className="row mb-5">
                        <div className="col-md-12 col-12 px-5">
                            <h2 className="text-center">{user.gender === "M" ? `Hola ${user.name} ${user.lastname}` : `Hola ${user.name} ${user.lastname}`}</h2>
                            {cardRows.map(cardRow =>
                                <div className="row mt-4">
                                    {cardRow.map(card => <PanelCard {...card}/> )}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
			</div>	
 		</div>	
    );
}

export default HomeUsuario;