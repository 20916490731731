import React from "react";
import ReactHtmlParser from "react-html-parser";

export function ComponenteCardA({ componente }) {
  const url = componente.url;
  const title = componente.title;
  const bgColor = componente.json_data.bg_color;
  const hoverColor = componente.json_data.hover_color;
  
  const handleMouseOver = () => {
    document.getElementById(componente.id + title).style.background = hoverColor;
  }
  
  const handleMouseLeave = () => {
    document.getElementById(componente.id + title).style.background = bgColor;
  }

  return (
    <a
      href={url ?? "#REA"}
      className="noticia-link"
      target="_self"
      rel="noopener noreferrer"
    >
      <div
        id={componente.id + title}
        className="d-flex align-items-center justify-content-center"
        style={{ height: 150, backgroundColor: bgColor || "white" }}
        onMouseOver={handleMouseOver}
        onMouseLeave={handleMouseLeave}
      >
        <div className="cardA-letra">{ReactHtmlParser(title)}</div>
      </div>
    </a>
  );
}
