import api from '../api/recursos';
import oauth from '../api/oauth';

const RecursosService = {
    getRecursos : (page, filters) => {
        // substring a filters porque siempre viene "?nombre_del_filtro=valor_del_filtro"
        return api.get(`resource/list?page=${page}&${filters.substring(1)}`)
    },
    getRecursoById : (id) => {
        return api.get(`resource/get/${id}`)
    },
    getRecursosSearch : (string) => {
        // substring a filters porque siempre viene "?nombre_del_filtro=valor_del_filtro"
        return api.get(`resource/list?keyword=${string}`)
    },
    enviarLikedRecurso : (recursoId, folder) => {
        if(folder===0){
            return oauth.post(`/api/like/${recursoId}`)
        }else{
            return oauth.post(`/api/like/${recursoId}/${folder}`)
        }
    },
    quitarLikedRecurso : (recursoId, folder=0) => {//0 es la carpeta de mis favoritos
        if(folder === 0){
            return oauth.delete(`/api/like/${recursoId}`)
        }else{
            return oauth.delete(`/api/like/${recursoId}/${folder}`)
        }
    },
    deleteResourceModdle:(id)=>{
        return oauth.delete(`/api/classroom/mod/${id}`)
    },
    cambiarCarpetaDeRecurso : (recursoId, new_folder_id, oldFolderId="") => {
        return oauth.patch(`/api/like/${recursoId}${oldFolderId !== "" ? "/"+oldFolderId : ""}`, {new_folder_id:new_folder_id})
    },
    cambiarOrdenDeRecurso : (recursoId, folderId, position) => {
        if(folderId===0){
            return oauth.patch(`/api/like/${recursoId}`, {new_position:position})
        }else{
            return oauth.patch(`/api/like/${recursoId}/${folderId}`, {new_position:position})
        }
    },
    cambiarOrdenDeCarpeta : (folderId, position) => {
            return oauth.patch(`/api/like/folder/${folderId}`, {new_position:position})
    },
    crearCarpetaRecursos : (title) => {
        return oauth.post(`/api/like/folder`, {title})
    },
    cambiarNombreCarpetaRecursos : (folderId, title) => {
        return oauth.patch(`/api/like/folder/${folderId}?title=${title}`)
    },
    eliminarCarpetaRecursos : (folderId) => {
        return oauth.delete(`/api/like/folder/${folderId}`)
    },
    getAllRecursos : () => {
        // substring a filters porque siempre viene "?nombre_del_filtro=valor_del_filtro"
        return api.get(`resource/list`)
    },
    getSectionsAulaModdle : (idAula, type_aula) => {
        return oauth.get(`/api/classroom/${type_aula}/${idAula}/sections`)
    },
    getUrlModdle : (type_aula, idAula, id_section, url, titleRecurso, isResource) => {
        if(isResource){
            return oauth.get(`api/classroom/${type_aula}/${idAula}/resource/add?section=${id_section}&externalurl=${url}&name=${titleRecurso}`)
        }else{
            return oauth.get(`api/classroom/${type_aula}/${idAula}/task/add?section=${id_section}&externalurl=${url}&name=${titleRecurso}`)
        }
    },
}

export {RecursosService as default}