import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import { BackofficeService } from 'src/axios/index'

import Contacto from 'src/components/institucionales/contacto';
import ContactoSuccess from 'src/components/institucionales/ContactoSuccess';
import Loading from 'src/components/shared/loading';

const ContactoContainer = ({ history, match, user, ...props }) => {
    const [contactText, setContactText] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const matchesSuccess = useRouteMatch('/contacto/exito');

    useEffect(() => {
        BackofficeService.fetchContactText()
            .then(function (response) {
                setContactText(response.contact);
                setSuccessMessage(response.message);
            });
    }, []);

    if (!contactText) {
		return <Loading />
	}

    if(matchesSuccess) {
        return (    
            <ContactoSuccess
                message={successMessage}
                history={history}
                match={match}
            />
        );
    }

    return (
        <Contacto
            contact={contactText}
            history={history}
            match={match}
            user={user}
        />
    );
}

const mapStateToProps = ({ authReducer }) => {
    return {
        user: authReducer.user
    };
};

export default connect(mapStateToProps)(ContactoContainer);
