import React from "react";
import ReactHtmlParser from 'html-react-parser';
import { IconDownload } from "src/components/icons/IconDownload";

export function ComponenteAgrupadorItem({ item })
{
    const text = ReactHtmlParser(item.text ?? '');
    const bgColor = item.bgColor;
    const textColor = "#000000";
    const isTargetBlank = (item.willTargetBlank && window.innerWidth > 768) ? true : false;

    return (
<div className="componente-agrupador-content-item" style={{
    '--bg-color': bgColor,
    '--text-color': textColor,
}}>
    <div className="componente-agrupador-content-item-text">
        { item.divURL ? <a href={item.divURL} target={isTargetBlank ? "_blank" : "_self"}> {text} </a> : <>{text}</> }
    </div>
    { item.iconURL &&
    <div className="componente-agrupador-content-item-icon">
        <a href={item.iconURL}>
            <IconDownload/>
        </a>
    </div>
    }
</div>
    )
}
