export default [
    {
        id: 1,
        name: "Inicio",
        link: "",
        slug: "*",
        components: [
            {
                id: 1,
                col: null,
                component_state_id: 1,
                component_type_id: 1,
                copete: "<p style='font-size: 120%;font-weight: 300;'><span style='color:rgb(0, 0, 0);font-family:Kanit;'></span></p>",
                description: null,
                image_file: {
                    id: 1,
                    filename: null,
                    alt_text: "",
                    styles: "{}"
                },
                page_background: {
                    id: 1,
                    color_hex: "#FFFFFF",
                    image_file: null,
                    type: "color"
                },
                row: null,
                search_box_text: null,
                size: null,
                title: "<h1 style='line-height: 50px; font-size: 40px;'><span style='color:rgb(0, 0, 0);'></span></h1>",
                type: {
                    id: 1,
                    name: "DESTACADO_PRINCIPAL",
                    display_name: "Destacado principal"
                },
                url: null
            },
        ]
    }
];