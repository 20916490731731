import React, { useEffect, useState } from 'react';
import {
  createListFromArray,
  ordernarXMenor,
  showDeveloperMessage,
} from "src/helpers";
import Titulo from "./componentes/Titulo";
import Texto from "./componentes/Texto";
import DestacadoCentro from "./componentes/DestacadoCentro";
import DestacadoPrincipal from './componentes/DestacadoPrincipal';
import DestacadoIzquierda from "./componentes/DestacadoIzquierda";
import BuscadorRecursos from "./componentes/BuscadorRecursos";
import Embebido from "./componentes/Embebido";
import Noticia from "./componentes/Noticia";
import { ComponenteAgrupador } from "./componentes/ComponenteAgrupador";
import { ComponenteBoton } from "./componentes/ComponenteBoton";
import { ComponenteCardA } from "./componentes/ComponenteCardA";
import DynamicCarousel from "./componentes/DynamicCarousel";
import DestacadoSinImagen from "./componentes/DestacadoSinImagen";
import { ComponenteConjuntoNoticias } from "./componentes/ComponenteConjuntoNoticias";

const COMPONENTS_TYPES = {
  DESTACADO_PRINCIPAL: 1,
  DESTACADO_IMAGEN_CENTRO: 2,
  DESTACADO_IMAGEN_IZQUIERDA: 3,
  TITULO: 4,
  TEXTO: 5,
  BUSCADOR_RECURSOS: 6,
  EMBEBIDO: 7,
  NOTICIAS: 8,
  AGRUPADOR: 9,
  BOTON: 10,
  SLIDER: 11,
  CARD_A: 12,
  DESTACADO_CENTRO_SIN_IMAGEN: 13,
  CONJUNTO_NOTICIAS: 14,
};

const Portada = (props) => {
  const { componentes, history } = props;

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    // Agrega el listener al evento 'resize'
    window.addEventListener('resize', handleResize);

    // Cleanup el listener cuando el componente se desmonta
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function calcularClaseColumnas(size, offset, components_type) {
    if (components_type === COMPONENTS_TYPES.CARD_A && isMobile && size === 1) {    
      return "col-" + 6 * size + (offset !== 0 ? " offset-" + offset : "") + " mb-3 cardA";
    } else if (components_type === COMPONENTS_TYPES.DESTACADO_CENTRO_SIN_IMAGEN && isMobile) {
      return "col-12 mb-3 cardA"; 
    } else if (components_type === COMPONENTS_TYPES.NOTICIAS && isMobile) {
      return "col-12" + (offset !== 0 ? " offset-" + offset : "") + " mb-3 cardA ";
    } else {
      return "col-" + 3 * size + (offset !== 0 ? " offset-" + offset : "") + " mb-3 cardA";
    }
  }

  return (
    <>
      <div className="wrapper-slider">
        {
          componentes.sort((a, b) => a.type.id <= b.type.id).map(c => {
            return (
              <div key={c.id}>
                {
                  c.type.id === COMPONENTS_TYPES.SLIDER
                  && c.json_data.es_destacado_principal
                  && <DynamicCarousel componente={c} key={c.id} />
                }

                {
                  c.type.id === COMPONENTS_TYPES.DESTACADO_PRINCIPAL
                  && <DestacadoPrincipal componente={c} key={c.id} />
                }
              </div>
            )
          })
        }
      </div>
      <div>
        <div className="container-fluid mb-4 p-0">
          {
            createListFromArray(componentes, 'row').map(i => {
              let tamanioOcupado = 0;
              return (
                <div className="row " key={i} >
                  {
                    componentes.filter(c => c.row === i).sort((a, b) => ordernarXMenor(a, b, 'col')).map(c => {
                      const offset = (c.col - tamanioOcupado - 1) * 3;
                      tamanioOcupado += c.size + offset / 3;
                      return (
                        <div className={calcularClaseColumnas(c.size, offset, c.type.id)} key={c.id}>
                          {c.type.id === COMPONENTS_TYPES.SLIDER && !c.json_data.es_destacado_principal && <DynamicCarousel componente={c} />}
                          {c.type.id === COMPONENTS_TYPES.TITULO && <Titulo componente={c} />}
                          {c.type.id === COMPONENTS_TYPES.TEXTO && <Texto componente={c} />}
                          {c.type.id === COMPONENTS_TYPES.DESTACADO_IMAGEN_IZQUIERDA && <DestacadoIzquierda componente={c} />}
                          {c.type.id === COMPONENTS_TYPES.DESTACADO_IMAGEN_CENTRO && <DestacadoCentro componente={c} />}
                          {c.type.id === COMPONENTS_TYPES.BUSCADOR_RECURSOS && <BuscadorRecursos componente={c} history={history} />}
                          {c.type.id === COMPONENTS_TYPES.EMBEBIDO && <Embebido componente={c} />}
                          {c.type.id === COMPONENTS_TYPES.NOTICIAS && <Noticia componente={c} />}
                          {c.type.id === COMPONENTS_TYPES.AGRUPADOR && <ComponenteAgrupador componente={c} />}
                          {c.type.id === COMPONENTS_TYPES.BOTON && <ComponenteBoton componente={c} />}
                          {c.type.id === COMPONENTS_TYPES.DESTACADO_CENTRO_SIN_IMAGEN && <DestacadoSinImagen componente={c} />}
                          {c.type.id === COMPONENTS_TYPES.CARD_A && (<ComponenteCardA componente={c} /> )}
                          { c.type.id === COMPONENTS_TYPES.CONJUNTO_NOTICIAS && <ComponenteConjuntoNoticias componente={c} /> }
                        </div>
                      );
                    })
                  }
                </div>
              );
            })
          }
        </div>
      </div>
    </>
  );
};

export default Portada;
