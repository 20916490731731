import React from "react";
import ReactHtmlParser from 'react-html-parser';

export function ComponenteBoton({ componente })
{
    const url = componente.url;
    const title = componente.title;
    const bgColor = componente.json_data.bg_color;
    const hoverColor = componente.json_data.hover_color;
    const alignment = componente.json_data?.align_in_column?.value;

    return (
        <div className="d-flex flex-column">
            <a
                href={url}
                className="componente-boton"
                style={{
                    '--bg-color': bgColor,
                    '--hover-color': hoverColor,
                    alignSelf: alignment,
                }}
            >
                { ReactHtmlParser(title) }
            </a>
        </div>
    )
}
