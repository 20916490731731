import React, { useEffect, useState } from 'react';
import XMLViewer from 'react-xml-viewer';

import { BackofficeService } from 'src/axios';
import Loading from '../shared/loading';

const Sitemap = (props) => {
    const [sitemap, setSitemap] = useState(null);

    useEffect(()=>{
        BackofficeService.getSitemap()
            .then((sitemap) => setSitemap(sitemap));
    }, [])

    if (!sitemap) {
		return <Loading />
    }

    return (
        <div className="main_content" style={{ marginTop: "30px" }}>
            {
                sitemap && <XMLViewer xml={sitemap} />
            }
        </div>
    );
};


export default Sitemap;