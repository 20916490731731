import React from "react";
import { Card, OverlayTrigger } from "react-bootstrap";
import ReactHtmlParser from "react-html-parser";

const NoticiaparaConjunto = ({ componente }) => {
  let {
    title,
    copete,
    slug,
    json_data,
    imagen1,
    publication_date,
    background_color,
  } = componente;

  imagen1 = json_data ? JSON.parse(json_data)[0] : null;


  const imageUrl1 = imagen1
    ? `${process.env.REACT_APP_BASE_URL_API}/${imagen1.partialFileName}`
    : null;

  const iconStyles1 = imagen1
    ? {
        height: `${imagen1.imageStyles.height}px`,
        width: `${imagen1.imageStyles.width}px`,
        maxWidth: "100%",
        minWidth: "100%",
        maxHeight: "220px",
        minHeight: "220px",
        backgroundSize: "cover",
        overflow: "hidden",
        borderRadius: `${imagen1.imageStyles.borderRadius}px`,
        transform: `rotate(${imagen1.imageStyles.rotate}deg) scale(${imagen1.imageStyles.scale})`,
        backgroundPosition: `${imagen1.imageStyles.positionX * 100}% ${
          imagen1.imageStyles.positionY * 100
        }%`,
      }
    : {};

  const date = new Date(publication_date).toLocaleDateString("es-ar", {
    year: "numeric",
    month: "long",
    day: "numeric",
    calendar: "iso8601",
    timeZone: "America/Buenos_Aires",
  });

  const allParagraphs = ReactHtmlParser(
    copete ? copete.replace(/!important/g, "") : ""
  );
  

  return (
    <Card
      className=""
      style={{height:"500px" ,backgroundColor: background_color ? background_color : "white" }}
    >
      <Card.Link
        href={`/${slug}` ?? "#"}
        target="_self"
        rel="noopener noreferrer"
      >
        <div className="overflow-hidden">
          <div className="noticia-img" style={iconStyles1}>
            {imageUrl1 && (
              <img src={imageUrl1} alt={imagen1.alt_text} className="mx-auto" />
            )}
          </div>
        </div>
        <Card.Body className="p-4">
          <div className="">
       
              <Card.Title
                className="noticia-title"
                id={`noticia-title-${componente.id}`}
              >
                {ReactHtmlParser(title ? title.replace(/!important/g, "") : "")}
              </Card.Title>
        
            
              <Card.Text
                className="noticia-copete"
                id={`noticia-copete-${componente.id}`}
              >
                {allParagraphs}
              </Card.Text>
           
          </div>

          <Card.Text className="noticia-date pt-3 separador ">
            <span className="">{date}</span>
          </Card.Text>
        </Card.Body>
      </Card.Link>
    </Card>
  );
};
export default NoticiaparaConjunto;
