import React, { Fragment, useState, useRef } from 'react';
import { NotificationManager } from 'react-notifications';
import { Fade } from 'reactstrap';
import Keyboard from 'react-simple-keyboard';
import { isMobile } from 'react-device-detect';
import ReactHtmlParser from 'react-html-parser';
import "react-simple-keyboard/build/css/index.css";

import { soportaDictadoPorVoz } from 'src/helpers';
import { VIDEO, GALERIA_IMAGENES, TEXTO, INTERACTIVO, LIBRO_ELECTRONICO, AUDIO } from 'src/util/constantes';
import RecursosService from 'src/axios/services/RecursosService';

import CarouselSearch from 'src/components/recursos/carouselSearch';
import IconoRecurso from 'src/components/recursos/IconoRecurso';

const BuscadorRecursos = props => {
  const { history, componente } = props;
  const [input, setInput] = useState("");
  const [layout, setLayout] = useState("default");
  const [showKeyboard, setShowKeyboard] = useState(false);
  const [searchResults, setSearchResults] = useState(null);
  const [loadingSearchResults, setLoadingSearchResults] = useState(false);
  
  const background = {
    color: {
        backgroundColor: componente.page_background.color_hex
    },
    image: {
      background: componente.page_background.image_file && `url(${process.env.REACT_APP_BASE_URL_API}/${componente.page_background.image_file.filename.replace("\\", "/").replace("\\", "/")})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover' 
    }
};

  const keyboard = useRef();

  const micro = () => {
    try {
      let SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
      let recognition = new SpeechRecognition();

      recognition.lang = "es-ES";
      recognition.continuous = false;
      recognition.interimResults = true;
      let texto = ""
      recognition.onresult = function (event) {
        for (let i = event.resultIndex; i < event.results.length; i++) {
          if (event.results[i].isFinal) {
            texto +=
              event.results[i][0].transcript;
            setInput(texto)
            onChangeInput({
              target: {
                value: texto
              }
            })
          }
        }
      }
      NotificationManager.info("Escuchando...")
      recognition.start();
    } catch (error) {
      console.error("Este navegador no soporta dictado por voz")
    }

  }

  const onChange = input => {
    if (input.length < 201) {
      setInput(input);
      onChangeInput({
        target: {
          value: input
        }
      })
    }
  };

  const handleShift = () => {
    const newLayoutName = layout === "default" ? "shift" : "default";
    setLayout(newLayoutName);
  };

  const onKeyPress = button => {
    if (button === "{shift}" || button === "{lock}") handleShift();
    if (button === "{enter}") {
      handleSubmit()
    }
  };

  const onChangeInput = event => {
    const text = event.target.value;
    if (text.length < 201) {
      setInput(text);
      getRecursosSearch(text)
      if (keyboard.current !== undefined) keyboard.current.setInput(text);
    }
  };

  const handleSubmit = (event) => {
    if (event) event.preventDefault()
    let searchKeywordValue = input;

    if (searchKeywordValue !== "")
      window.location = `${process.env.REACT_APP_REA}/recursos?keyword=${searchKeywordValue}`;
  };


  const getRecursosSearch = async (search) => {
    setLoadingSearchResults(true)
    try {
      const response = await RecursosService.getRecursosSearch(search);
      let data = response.data;
      if (!data) {
        data = []
      }

      setSearchResults(data.length > 12 ? data.slice(0,12) : data)
      setLoadingSearchResults(false)
    } catch (error) {
      setLoadingSearchResults(false)
    }
  }

  return (
    <Fragment>
      <div 
        className="card card-search shadow-none jm-bgk-img" 
        style={
          background[componente.page_background.type]
        }
      >
        <div className="row">
          <div className="col-12" style={{ verticalAlign: "middle" }}>
            {ReactHtmlParser(componente.title ? componente.title.replace(/!important/g, "") : '')}
            <div>
              <div className="bubble mt-4">
                <form role="search" autoComplete="off" onSubmit={handleSubmit}>
                  <div className="input-group input-group-lg">
                    <input type="text" value={input} id="searchKeywordValue" className="form-control"
                      style={{ border: "none" }} placeholder={componente.search_box_text}
                      aria-label="Recipient's username" aria-describedby="basic-addon2" onChange={onChangeInput} maxLength={200} />
                    <cite id="lacita"></cite>
                    <div className="input-group-append">
                      <button id="btn-search" className="btn btn-outline-primary d-none d-sm-block" type="submit"><i className="fa fa-search"></i></button>
                      <button id="btn-keyboard" className="btn btn-outline-primary d-none d-sm-block"
                        type="button" onClick={() => setShowKeyboard(!showKeyboard)}>
                        <i className="fa fa-keyboard-o"></i>
                      </button>
                      {
                        soportaDictadoPorVoz() &&
                        <button className="btn btn-outline-primary" onClick={micro} type="button" id="procesar"><i className="fa fa-microphone"></i></button>
                      }
                    </div>
                  </div>
                </form>
              </div>
              <div className="pointer" style={{ display: showKeyboard && 'none' }}></div>
              <div className='container-keyboard'>
                {
                  showKeyboard && (
                    <Keyboard
                      keyboardRef={r => (keyboard.current = r)}
                      layoutName={layout}
                      onChange={onChange}
                      onKeyPress={onKeyPress}
                      layout={{
                        'default': [
                          '` 1 2 3 4 5 6 7 8 9 0 - = {bksp}',
                          '{tab} q w e r t y u i o p [ ] \\',
                          '{lock} a s d f g h j k l ñ ; \' {enter}',
                          '{shift} z x c v b n m , . / {shift}',
                          '.com @ {space}'
                        ],
                        'shift': [
                          '~ ! @ # $ % ^ & * ( ) _ + {bksp}',
                          '{tab} Q W E R T Y U I O P { } |',
                          '{lock} A S D F G H J K L Ñ : " {enter}',
                          '{shift} Z X C V B N M < > ? {shift}',
                          '.com @ {space}'
                        ]
                      }}
                      display={{
                        '{bksp}': 'Borrar',
                        '{enter}': 'Enter',
                        '{lock}': 'Bloq Mayus',
                        '{space}': ' ',
                        '{shift}': 'shift',
                        '{tab}': 'tab'
                      }}
                    />
                  )
                }
              </div>
              <div className="row mt-3">
                {/* <div className="col-md-1 d-none d-lg-block"></div> */}
                <IconoRecurso format={VIDEO} className="col-md-2 d-none d-lg-block pr-0 iconos-home-recursos">
                  VIDEOS
                </IconoRecurso>
                <IconoRecurso format={GALERIA_IMAGENES} className="col-md-2 d-none d-lg-block pr-0 iconos-home-recursos">
                  IMÁGENES
                </IconoRecurso>
                <IconoRecurso format={TEXTO} className="col-md-2 d-none d-lg-block pr-0 iconos-home-recursos">
                  TEXTOS
                </IconoRecurso>
                <IconoRecurso format={INTERACTIVO} className="col-md-2 d-none d-lg-block pr-0 iconos-home-recursos">
                  INTERACTIVOS
                </IconoRecurso>
                <IconoRecurso format={LIBRO_ELECTRONICO} className="col-md-2 d-none d-lg-block pr-0 iconos-home-recursos">
                  LIBROS
                </IconoRecurso>
                <IconoRecurso format={AUDIO} className="col-md-2 d-none d-lg-block pr-0 iconos-home-recursos">
                  AUDIO
                </IconoRecurso>
                {/* <div className="col-md-1 d-none d-lg-block"></div> */}
              </div>
            </div>

            <Fade in={input.length > 0 && !isMobile} tag="div" className="row" style={showKeyboard ? { marginTop: '14rem' } : {}}>
              <div className="container-fluid">
                <div className="searchResultBox" >
                  <div className="col-md-12 carousel-container">
                    <CarouselSearch searchResults={searchResults} loadingSearchResults={loadingSearchResults} history={history} />
                  </div>
                </div>
              </div>
            </Fade>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default BuscadorRecursos;