import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';


import { BackofficeService } from 'src/axios';
import {  redirectOAuth } from 'src/helpers/authHelper';
import HomeUsuario from 'src/components/usuarioDatos/homeusuario';
import Loading from 'src/components/shared/loading';

const HomeUsuarioContainer = (props) => {
    const { logged_in, userLoading, location, user } = props;

    useEffect(() => {
        const urlParams = new URLSearchParams(location.search);

        if (!urlParams.has('code') && !logged_in && !userLoading) {
            redirectOAuth();
        }
        //eslint-disable-next-line
    }, []);

    const [cards, setCards] = useState([]);

    useEffect(() => {
        BackofficeService.fetchUserPanel()
            .then((data) => {
                setCards(data);
            });
    }, [setCards]);

    if (!logged_in) {
        return <Loading />
    }

    return (
        <HomeUsuario
            cards={cards}
            user={user}
        />
    );
}

const mapStateToProps = ({ authReducer }) => {
    return {
        user: authReducer.user,
        userLoading: authReducer.loading,
        logged_in: authReducer.user_logued
    };
};

export default connect(mapStateToProps)(HomeUsuarioContainer);
