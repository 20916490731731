import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { getTempPaginaInstitucionalDataBackoffice, getPaginaInstitucionalDataBackoffice } from 'src/redux/actions/backofficeActions';

import Loading from 'src/components/shared/loading';
import PaginaInstitucional from 'src/components/backoffice/PaginaInstitucional';

const PreviewInstitucionalesContainer = props => {
    const { match, previewInstitucionalesReducer, history } = props;

    useEffect(()=>{
        const paginaID = match.params.paginaId;
        if(paginaID < 999999)
            props.getPaginaInstitucionalDataBackoffice(match.params.paginaId);
        else
            props.getTempPaginaInstitucionalDataBackoffice(match.params.paginaId);
        // eslint-disable-next-line
    }, [])

    if (!previewInstitucionalesReducer.pagina) {
		return <Loading />
	}
    return (
        <PaginaInstitucional
            pagina={previewInstitucionalesReducer.pagina}
            history={history}
        />
    );
}

const mapStateToProps = ({ authReducer, previewInstitucionalesReducer }) => {
    return {
        authReducer,
        previewInstitucionalesReducer
    };
};

const mapDispatchToProps = {
    getPaginaInstitucionalDataBackoffice,
    getTempPaginaInstitucionalDataBackoffice
}

export default connect(mapStateToProps, mapDispatchToProps)(PreviewInstitucionalesContainer);
