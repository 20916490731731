export const GALERIA_IMAGENES = 1;
export const AUDIO = 2;
export const TEXTO = 3;
export const VIDEO = 4;
export const INTERACTIVO = 5;
export const COLECCION = 6;
export const LIBRO_ELECTRONICO = 7;

export const jurisdiccionFilter = {
    key: 'jurisdiccion',
    display_name: 'Jurisdicción',
    elements: [
        {
            id: 1,
            display_name: "Buenos Aires"
        },
        {
            id: 2,
            display_name: "CABA"
        },
        {
            id: 3,
            display_name: "Catamarca"
        },
        {
            id: 4,
            display_name: "Chaco"
        },
        {
            id: 5,
            display_name: "Chubut"
        },
        {
            id: 6,
            display_name: "Córdoba"
        },
        {
            id: 7,
            display_name: "Corrientes"
        },
        {
            id: 8,
            display_name: "Entre Ríos"
        },
        {
            id: 9,
            display_name: "Formosa"
        },
        {
            id: 10,
            display_name: "Jujuy"
        },
        {
            id: 11,
            display_name: "La Pampa"
        },
        {
            id: 12,
            display_name: "La Rioja"
        },
        {
            id: 13,
            display_name: "Mendoza"
        },
        {
            id: 14,
            display_name: "Misiones"
        },
        {
            id: 15,
            display_name: "Neuquén"
        },
        {
            id: 16,
            display_name: "Río Negro"
        },
        {
            id: 17,
            display_name: "Salta"
        },
        {
            id: 18,
            display_name: "San Juan"
        },
        {
            id: 19,
            display_name: "San Luis"
        },
        {
            id: 20,
            display_name: "Santa Cruz"
        },
        {
            id: 21,
            display_name: "Santa Fe"
        },
        {
            id: 22,
            display_name: "Santiago del Estero"
        },
        {
            id: 23,
            display_name: "Tierra del Fuego"
        },
        {
            id: 24,
            display_name: "Tucumán"
        }
    ]
}

export const NOT_REDIRECT=true


export const JAVA_CODE_DATOS_ABIERTOS = `
import java.io.BufferedReader;
import java.io.InputStreamReader;
import java.net.HttpURLConnection;
import java.net.URL;

class Main {
	public static void main(String[] args) {
		String url = "${process.env.REACT_APP_BASE_URL}resource/list";
		String respuesta = "";
		try {
			respuesta = peticionHttpGet(url);
			System.out.println("La respuesta es:\\n" + respuesta);
		} catch (Exception e) {
			// Manejar excepción
			e.printStackTrace();
		}
	}

	public static String peticionHttpGet(String urlParaVisitar) throws Exception {
		// Esto es lo que vamos a devolver
		StringBuilder resultado = new StringBuilder();
		// Crear un objeto de tipo URL
		URL url = new URL(urlParaVisitar);

		// Abrir la conexión e indicar que será de tipo GET
		HttpURLConnection conexion = (HttpURLConnection) url.openConnection();
		conexion.setRequestMethod("GET");
		// Búferes para leer
		BufferedReader rd = new BufferedReader(new InputStreamReader(conexion.getInputStream()));
		String linea;
		// Mientras el BufferedReader se pueda leer, agregar contenido a resultado
		while ((linea = rd.readLine()) != null) {
			resultado.append(linea);
		}
		// Cerrar el BufferedReader
		rd.close();
		// Regresar resultado, pero como cadena, no como StringBuilder
		return resultado.toString();
	}
}
`
export const JS_CODE_DATOS_ABIERTOS=
`
const getListResources = () => {
    // Hacemos una petición con la funcion nativa de JS
    fetch("${process.env.REACT_APP_BASE_URL}resource/list", {
        headers: {
            "Accept": "application/json" // Header de la petición requerido
        }
    })
    .then(response => response.json()) // Convertimos la respuesta a JSON
    .then(data => {
        console.log("Datos de recursos: \\n", data) // Ya aquí podemos tener la informacion devuelta por la API.
    })
    .catch(error => {
        console.log("Error al obtener los datos de los recursos: \\n", error) // Un bloque CATCH para el manejo de errores
    })
}
`
export const PHP_CODE_DATOS_ABIERTOS=
`
function getListResources() {
    $url = '${process.env.REACT_APP_BASE_URL}resource/list';
    
    $headers = array (
            'Content-Type: application/json' // Agreamos header requerido
    );

    $ch = curl_init (); // Abrimos la conexión
    curl_setopt ( $ch, CURLOPT_URL, $url );
    curl_setopt ( $ch, CURLOPT_HTTPHEADER, $headers );
    curl_setopt ( $ch, CURLOPT_RETURNTRANSFER, true );

    $result = curl_exec ( $ch ); // Ejecutamos la conexión. Cabe recalcar que en $result tendremos la información devuelta por la api
    echo $result; // Imprimimos resultados en consola
    curl_close ( $ch ); // Cerramos la conexión
}
`

export const PYTHON_CODE_DATOS_ABIERTOS=
`
import requests # Esta libreria se debe instalar antes, con "pip install requests"
import json     # Esta libreria se debe instalar antes, con "pip install json"

url = '${process.env.REACT_APP_BASE_URL}resource/list'

headers = {'Accept': 'application/json'} # Agregamos los headers correspondientes

req = requests.get(url, headers=headers) # Ejecutamos la petición a la API

json_body=req.json() # Obtenemos un objeto tipo JSON

print(json_post) # Mostramos por pantalla dicho objeto
`

export const NET_CODE_DATOS_ABIERTOS=
`
En construcción...
`

export const JSON_CODE_DATOS_ABIERTOS = `
{
    "swagger": "2.6",
    "info": {
        "title": "Portal educ.ar Web Services API",
        "version": "1.0.0"
    },
    "basePath": "/api/rest",
    "paths": {
        "/resource/list": {
            "get": {
                "tags": [
                    "Recursos"
                ],
                "summary": "Listado de Recursos",
                "parameters": [
                    {
                        "name": "keyword",
                        "in": "query",
                        "description": "Filtro por Palabra",
                        "required": false,
                        "type": "string"
                    },
                    {
                        "name": "category_id",
                        "in": "query",
                        "description": "Categor\u00eda",
                        "required": false,
                        "type": "integer"
                    },
                    {
                        "name": "resources_format_id",
                        "in": "query",
                        "description": "Formato de Recurso",
                        "required": false,
                        "type": "integer"
                    },
                    {
                        "name": "modality_id",
                        "in": "query",
                        "description": "Modalidad",
                        "required": false,
                        "type": "integer"
                    },
                    {
                        "name": "audience_id",
                        "in": "query",
                        "description": "Audiencia (inhabilitado)",
                        "required": false,
                        "type": "integer"
                    },
                    {
                        "name": "subject_id",
                        "in": "query",
                        "description": "Materia (inhabilitado)",
                        "required": false,
                        "type": "integer"
                    },
                    {
                        "name": "level_id",
                        "in": "query",
                        "description": "Nivel (inhabilitado)",
                        "required": false,
                        "type": "integer"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "OK"
                    },
                    "500": {
                        "description": "Error desconocido"
                    },
                    "default": {
                        "description": "Error no contemplado"
                    }
                }
            }
        },
        "/resource/get/{id}: {
            "get": {
                "tags": [
                    "Recursos"
                ],
                "summary": "Show the specified resource",
                "parameters": [
                    {
                        "name": "id",
                        "in": "path",
                        "description": "UUID",
                        "required": true,
                        "type": "integer"
                    }
                ],
                "responses": {
                    "200": {
                        "description": "OK"
                    },
                    "404": {
                        "description": "Recurso no encontrado"
                    },
                    "500": {
                        "description": "Error desconocido"
                    },
                    "default": {
                        "description": "Error no contemplado"
                    }
                }
            }
        }
    },
    "definitions": {}
}
`