
import React from 'react';
import BreadCrumb from '../shared/breadcrumb';

const Capacitacion = props => {
    const { history, match } = props;

    return (
        <div className="main_content">
            <div className="container-fluid">
                <div className="col-xl-8 offset-xl-2 col-12">
                    <BreadCrumb match={match} history={history} actualPosition={'Capacitación'} previousPositions={['Inicio']} />
                </div>
            </div>
            <div className="col-xl-8 offset-xl-2 col-12 mb-5 mt-5">
                <div className="col-md-12 px-0">
                    <div className="row">
                        <div className="col-md-12 text-left">
                            <h1 className="display-5"><span className="tc-1">Capacitación</span></h1>
                            <p>Los cursos están disponibles en la plataforma de Seguimos Educando. Para realizar la inscripción es necesario tener un usuario en ese sitio. </p>
                        </div>
                    </div>
                    <div className="row" >
                        <div className="col-md-12 text-justify">
                            <h5 className="font-weight-bold mb-2">Introducción a los diferentes modos y entornos de educación virtual</h5>
                            <p>Reflexiones sobre los diferentes modos de educación virtual (educación a distancia, e learning, blendend learning, etc.), conceptos e ideas para poder conceptualizar las situaciones de enseñanza que transitamos durante las diversas fases de aislamiento y pensar las posibilidades y límites y tomar decisiones sobre cómo enseñar en una plataforma virtual teniendo un marco general de la historia y tendencias en el campo.</p>
                            <span>&#8226; Duración: 10 horas.</span><br/>
                            <span>&#8226; Inscripción: Abierta.</span><br/>
                            {/* <span>&#8226; Inicio: 1 de septiembre.</span><br/> */}
                        </div>
                    </div>
                    <br/>
                    <div className="row" >
                        <div className="col-md-12 text-justify">
                            <h5 className="font-weight-bold mb-2">Diseñar y planificar una clase virtual I</h5>
                            <p>Los primeros pasos para llevar adelante actividades educativas en entornos virtuales:  una planificación didáctica, curricular y estratégica que el docente realiza en diálogo con su nuevo entorno de enseñanza. ¿Cómo organizar las clases, los materiales y/o las tareas en el marco de ambientes virtuales?</p>
                            <span>&#8226; Duración: 10 horas.</span><br/>
                            <span>&#8226; Inscripción: Abierta.</span><br/>
                            {/* <span>&#8226; Inicio: 1 de septiembre.</span><br/> */}
                        </div>
                    </div>
                    <br/>
                    <div className="row" >
                        <div className="col-md-12 text-justify">
                            <h5 className="font-weight-bold mb-2">Diseñar y planificar una clase virtual II</h5>
                            <p>Un recorrido por los espacios y herramientas de un aula virtual para conocer las diferentes opciones para planificar clases sincrónicas y asincrónicas.</p>
                            <span>&#8226; Duración: 10 horas.</span><br/>
                            <span>&#8226; Inscripción: Abierta.</span><br/>
                            {/* <span>&#8226; Inicio: 15 de septiembre.</span><br/> */}
                        </div>
                    </div>
                    <br/>
                    <div className="text-center"><a className="btn btn-success mr-auto ml-auto" href="https://sgi.educ.ar/cursos?category=6" target="e_blank">Inscripción</a></div>

                </div>
            </div>
        </div>
    );
}

export default Capacitacion;