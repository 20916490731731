import {
    GET_PORTADA_BACKOFFICE,
    GET_PORTADA_BACKOFFICE_SUCCESS,
    GET_PORTADA_BACKOFFICE_FAILURE
} from '../types';

const INIT_STATE = {
    portada: null,
    loading: false
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_PORTADA_BACKOFFICE:
            return {
                ...state,
                loading: true
            }
        case GET_PORTADA_BACKOFFICE_SUCCESS:
            return {
                ...state,
                portada: action.payload,
                loading: false
            }
        case GET_PORTADA_BACKOFFICE_FAILURE:
            return {
                ...state,
                loading: false
            }
        default: return { ...state };
    }
}
