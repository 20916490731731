
import axios from 'axios';
import { json as jsonHeaders } from '../config/headers';

const axiosInstance = axios.create({
   baseURL: process.env.REACT_APP_BASE_URL,
   timeout: 60000,
   headers: jsonHeaders
});

axiosInstance.interceptors.request.use(
   request => requestHandler(request)
);

axiosInstance.interceptors.response.use(
   response => successHandler(response),
   error => errorHandler(error)
)

const requestHandler = (request) => (
   request
);

const errorHandler = (error) => (
    Promise.reject({...error})
      .catch(function(error) {
         console.log(error);
         throw error;
      })
);

const successHandler = (response) => (
   response.data
);

export default axiosInstance;
